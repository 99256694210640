import React, { useEffect } from "react";

export const MathJax = ({ children }) => {
  useEffect(() => {
    if (!!children && !!window?.MathJax) {
      window.MathJax.typesetClear();
      window.MathJax.typeset();
    }
  }, [children]);

  return !!children ? <span>{children}</span> : null;
};
