import React, { useEffect } from "react";
import { oneOf, string } from "prop-types";
import { ImageFromFirebase } from "../../../components";

export const QTechnicalInstruction = React.memo(({ type, text, imageLink }) => {
  // needed to render latex
  useEffect(() => {
    if (!!window?.MathJax) {
      window.MathJax.typesetClear();
      window.MathJax.typeset();
    }
  }, [text]);

  return type === "text" ? (
    <h4 style={{ fontSize: "20px" }}>
      <em className="text-break">{text}</em>
    </h4>
  ) : type === "image" ? (
    <ImageFromFirebase
      className="mt-3 mb-3"
      isFluid={true}
      path={imageLink}
      rounded
    />
  ) : null;
});

QTechnicalInstruction.propTypes = {
  type: oneOf(["text", "image"]).isRequired,
  text: string,
  imageLink: string,
};

QTechnicalInstruction.displayName = "QTechnicalInstruction";
