import React, { useContext } from "react";
import { Row, Col, Image } from "react-bootstrap";
import { bool, func, object, string } from "prop-types";
import { ExamsAccordion, HelpModal, PDFLInk } from ".";
import { isEmpty } from "lodash";
import { LanguageContext } from "../../../languages";
import { displaySubject } from "../../../utils/helpers";

import styles from "./topic.module.scss";

export const Topic = React.memo(
  ({ courseName, getTeacherNames, isPartOfClassroom, topic }) => {
    const { displayText, language } = useContext(LanguageContext);

    return (
      <>
        {/* title */}
        <Row id={topic.name}>
          <Col>
            <p className={styles.topicName}>{topic.name}</p>
            <hr />
          </Col>
        </Row>
        {/* description, material and exams */}
        <Row className="mt-3">
          <Col lg={6}>
            <div className="d-flex align-items-center mb-3">
              <Image
                className="mr-2"
                height="35"
                src="/images/topics.png"
                width="35"
              />
              <h4 className={styles.subtitle}>
                {displaySubject(topic.subject, language)}
              </h4>
            </div>
            <p className="mb-4">
              {topic.description &&
                topic.description.split("\\n").map((c) => (
                  <span key={c} className="d-block text-muted">
                    {String(c).trim()}
                  </span>
                ))}
            </p>
            {!isEmpty(topic.material) && (
              <div className="mb-2">
                <div className="d-flex align-items-center mb-3">
                  <Image
                    className="mr-2"
                    height="35"
                    src="/images/material.png"
                    width="35"
                  />
                  <h4 className={styles.subtitle}>
                    {displayText("coursePage.material")}
                  </h4>
                </div>
                {topic.material
                  .sort((a, b) => a.materialOrderNumber - b.materialOrderNumber)
                  .map((m) => {
                    return (
                      <div key={m._id} className="mb-1">
                        {m.type === "video" && (
                          <a
                            className="text-info"
                            href={m.link}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            <i className="fas fa-video mr-2" />
                            {m.name}
                          </a>
                        )}
                        {m.type === "pdf" && (
                          <PDFLInk key={m._id} path={m.link} name={m.name} />
                        )}
                      </div>
                    );
                  })}
              </div>
            )}
          </Col>
          <Col lg={6} className="mt-2 mt-lg-0">
            <div className="d-flex align-items-center mb-3">
              <Image
                className="mr-2"
                height="35"
                src="/images/exams.png"
                width="35"
              />
              <h4 className={styles.subtitle}>
                {displayText("coursePage.exams")}
              </h4>
            </div>
            <ExamsAccordion
              exams={topic.exams}
              reward={topic.reward}
              topicName={topic.name}
              topicId={topic._id}
              freestyle={{
                ...topic.freestyle,
                isAvailable: topic.hasReward,
                topicId: topic._id,
              }}
            />
            {isPartOfClassroom && (
              <div className="text-right mt-2">
                <HelpModal
                  {...{
                    courseName,
                    getTeacherNames,
                    topic: topic.name,
                    topicId: topic._id,
                  }}
                />
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }
);

Topic.propTypes = {
  courseName: string.isRequired,
  getTeacherNames: func,
  isPartOfClassroom: bool,
  topic: object.isRequired,
};

Topic.displayName = "Topic";
