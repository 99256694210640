import React, { useState, useEffect, useContext } from "react";
import { Container, Spinner } from "react-bootstrap";
import { StudentLayout } from "../../components";
import { useSelector } from "react-redux";
import { fetchFreestyle } from "../../services";
import { QuestionsContainer } from "../../components";
import { LanguageContext } from "../../languages";

export const FreestylePage = () => {
  const course = useSelector((state) => state.course);
  const exam = useSelector((state) => state.exam);

  const [freestyle, setFreestyle] = useState();

  const { displayText } = useContext(LanguageContext);

  useEffect(() => {
    try {
      fetchFreestyle(course._id, exam.topicId).then((res) => {
        if (!res.data.length) {
          alert(displayText("freestylePage.alert"));
          window.location.href = "/course";
        } else {
          setFreestyle(res.data);
        }
      });
    } catch (err) {
      console.log(err);
      alert(displayText("freestylePage.alert"));
      window.location.href = "/course";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StudentLayout isZen={true}>
      {freestyle ? (
        <>
          <Container>
            <h1 className="examNameStyle mt-4">
              <strong>{exam.name}</strong>
              <small className="ml-2">
                <i className="fas fa-bolt" />
              </small>
            </h1>
          </Container>
          <QuestionsContainer questions={freestyle} isFreestyle={true} />
          <br />
          <br />
        </>
      ) : (
        <div className="text-center mt-4 pt-4">
          <Spinner animation="border" variant="success" />
        </div>
      )}
    </StudentLayout>
  );
};
