import React, { useCallback, useEffect, useState } from "react";
import { isEqual } from "lodash";
import {
  AdminNavigation,
  GuestNavigation,
  StudentNavigation,
  TeacherNavigation,
} from "./navigation";
import { AdminSpinner } from "./components";
import { USERS } from "./utils/constants";
import { firebaseAuth } from "./firebase/firebase";
import { useFirebase } from "./firebase/useFirebase";

const App = () => {
  const [navigation, setNavigation] = useState();

  const { handleSetStudentNavigation, handleSetTeacherNavigation } =
    useFirebase();

  const getUserType = useCallback((user) => {
    const { displayName, email } = user;
    if (isEqual(displayName, USERS.TEACHER)) return USERS.TEACHER;
    if (isEqual(email, USERS.ADMIN)) return USERS.ADMIN;
    if (displayName) return USERS.STUDENT;
    return USERS.GUEST;
  }, []);

  const getNavigation = useCallback(() => {
    switch (navigation) {
      case USERS.GUEST:
        return <GuestNavigation />;
      case USERS.STUDENT:
        return <StudentNavigation />;
      case USERS.TEACHER:
        return <TeacherNavigation />;
      case USERS.ADMIN:
        return <AdminNavigation />;
      default:
        return <GuestNavigation />;
    }
  }, [navigation]);

  useEffect(() => {
    firebaseAuth.onAuthStateChanged(async (user) => {
      // if no user, return guest
      if (!user) {
        setNavigation(USERS.GUEST);
        return;
      }

      // get user type
      const userType = getUserType(user);

      // logic for student navigation
      if (isEqual(userType, USERS.STUDENT)) {
        const nav = await handleSetStudentNavigation(user);
        setNavigation(nav);
        return;
      }

      // logic for teacher navigation
      if (isEqual(userType, USERS.TEACHER)) {
        const nav = await handleSetTeacherNavigation(user);
        setNavigation(nav);
        return;
      }

      // logic for admin navigation
      if (isEqual(userType, USERS.ADMIN)) {
        setNavigation(USERS.ADMIN);
        return;
      }

      // default is guest
      setNavigation(USERS.GUEST);
    });
  }, [getUserType, handleSetStudentNavigation, handleSetTeacherNavigation]);

  return navigation ? getNavigation() : <AdminSpinner />;
};

export default App;
