import React, { createRef, forwardRef, useState } from "react";
import { isEmpty, isEqual } from "lodash";
import { Button, Form, ListGroup } from "react-bootstrap";
import { ImageFromFirebase } from "../components";
import { errorLogger } from "../errors/errorLogger";
import { newConversation } from "../services";

export const useResults = (senderId, receiverId) => {
  const [showTextBox, setShowTextBox] = useState();

  const getTextOrImage = (value, textAccessor, imageAccessor) => {
    if (!value || !textAccessor || !imageAccessor) return null;
    if (isEqual(value.type, "text"))
      return (
        <strong className="mb-2">
          <em>{value[textAccessor]}</em>
        </strong>
      );
    if (isEqual(value.type, "image"))
      return (
        <ImageFromFirebase
          className="mb-2"
          height="100"
          path={value[imageAccessor]}
          rounded
          width="100"
        />
      );
    return null;
  };

  const getResult = (correct, userAnswer) => {
    if (isEqual(correct.length, 1)) {
      const correctAnswer = correct[0].answer;
      if (isEqual(correctAnswer, userAnswer))
        return (
          <small>
            <strong className="text-success">CORRECTO</strong>
          </small>
        );
      return (
        <>
          <small>
            <strong className="text-danger">INCORRECTO</strong>
          </small>
          <small>
            <strong>{`Respuesta Correcta: ${correctAnswer}`}</strong>
          </small>
        </>
      );
    }
  };

  const getOriginFromQuestion = (question) => {
    if (!question) return "";
    if (
      question?.qTechnicalInstruction &&
      isEqual(question?.qTechnicalInstruction?.type, "text")
    ) {
      return `${question?.qInstruction} | ${question?.qTechnicalInstruction?.text}`.trim();
    } else {
      return question?.qInstruction;
    }
  };

  const handleSendMessage = (ref, question) => {
    const data = {
      text: ref.current.value,
      origin: getOriginFromQuestion(question),
      sender: { teacher: senderId },
      receiver: { student: receiverId },
      ...(question?.qTechnicalInstruction?.imageLink && {
        image: question?.qTechnicalInstruction?.imageLink,
      }),
    };
    newConversation(data)
      .then(() => {
        alert("Mensaje enviado.");
        setShowTextBox((prevState) => (prevState ? null : question?._id));
      })
      .catch((err) => errorLogger(err));
  };

  const Question = forwardRef(({ question }, ref) => (
    <ListGroup.Item className="shadow-sm d-flex flex-column">
      <section className="d-flex flex-column mb-4">
        <strong className="mb-2">
          <em>{question?.qInstruction}</em>
        </strong>
        {getTextOrImage(question?.qTechnicalInstruction, "text", "imageLink")}
      </section>
      <small>
        <strong>{`Respuesta de alumno: ${question?.userAnswers?.answer}`}</strong>
      </small>
      {getResult(question?.qCorrectAnswers, question?.userAnswers?.answer)}
      <div>
        <Button
          className="shadow-sm my-2"
          onClick={() =>
            setShowTextBox((prevState) => (prevState ? null : question?._id))
          }
          size="sm"
          variant="dark"
        >
          {showTextBox && isEqual(showTextBox, question?._id) ? (
            <i className="fas fa-times" />
          ) : (
            "Mensaje"
          )}
        </Button>
      </div>
      {showTextBox && isEqual(showTextBox, question?._id) && (
        <div>
          <Form.Control
            as="textarea"
            className="mb-2"
            maxLength="500"
            ref={ref}
            rows="3"
            type="text"
          />
          <Button
            onClick={() => handleSendMessage(ref, question)}
            size="sm"
            variant="primary"
          >
            Enviar
          </Button>
        </div>
      )}
    </ListGroup.Item>
  ));

  const renderResults = (results) => {
    if (!results || isEmpty(results)) return [];
    const textBoxRef = createRef();
    return (
      <ListGroup>
        {results.map((q, idx) => (
          <Question question={q} ref={textBoxRef} key={idx} />
        ))}
      </ListGroup>
    );
  };

  return { renderResults };
};
