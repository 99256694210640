import React, { memo, useEffect, useMemo, useState } from "react";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import { errorLogger } from "../../../errors/errorLogger";
import { fetchClassroomTopicReport } from "../../../services";
import { useLocation } from "react-router-dom";
import { AdminSpinner } from "../../../components";
import { isEmpty, isEqual } from "lodash";
import cn from "classnames";
import { PieChart } from "./charts/PieChart";

import styles from "./classroomtabs.module.scss";

export const ClassroomTabs = memo(({ catalogue = [] }) => {
  const [selectedTopic, setSelectedTopic] = useState();
  const [selectedTopicName, setSelectedTopicName] = useState();
  const [report, setReport] = useState(null);

  const location = useLocation();

  const classroomId = useMemo(() => {
    const arr = location.pathname.split("/");
    return arr[arr.length - 1];
  }, [location]);

  const tabs = useMemo(
    () =>
      catalogue.reduce((acc, cv) => {
        acc.push(...cv.topics);
        return acc;
      }, []),
    [catalogue]
  );

  useEffect(() => {
    if (!selectedTopic) return;
    fetchClassroomTopicReport(classroomId, selectedTopic)
      .then((res) =>
        setReport(
          res.data.sort((a, b) => (a.studentName > b.studentName ? 1 : -1))
        )
      )
      .catch((err) => errorLogger(err));
  }, [classroomId, selectedTopic]);

  return (
    <Tab.Container activeKey={selectedTopic}>
      <Row>
        <Col className="rounded p-0" sm={3}>
          <Nav
            variant="pills"
            className={cn("flex-column p-2 rounded", styles.leftCol)}
          >
            {catalogue.map((course) => (
              <Nav.Item className="my-4" key={course._id}>
                <p className="mb-2">
                  <strong>{course.name.toLocaleUpperCase()}</strong>
                </p>
                <hr />
                {course.topics.map((topic) => (
                  <Nav.Link
                    className={cn(
                      "p-0 px-2",
                      styles.topic,
                      isEqual(selectedTopic, topic._id) && styles.active
                    )}
                    eventKey={topic._id}
                    key={topic._id}
                    onClick={() => {
                      if (isEqual(topic._id, selectedTopic)) {
                        setSelectedTopic(undefined);
                        setSelectedTopicName(undefined);
                        setReport(null);
                      } else {
                        setSelectedTopic(topic._id);
                        setSelectedTopicName(topic.name);
                        window.scrollTo(0, 0);
                      }
                    }}
                  >
                    {`• ${topic.name}`}
                  </Nav.Link>
                ))}
              </Nav.Item>
            ))}
          </Nav>
        </Col>
        <Col sm={9}>
          <Tab.Content>
            {tabs.map((t) => (
              <Tab.Pane key={t._id} eventKey={t._id}>
                {!report && <AdminSpinner />}
                {report && isEmpty(report) && <div>No hay resultados.</div>}
                {report && !isEmpty(report) && (
                  <>
                    <h2 className={styles.topicTitle}>
                      {selectedTopicName.toLocaleUpperCase()}
                    </h2>
                    <PieChart {...{ report, selectedTopic }} />
                  </>
                )}
              </Tab.Pane>
            ))}
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  );
});
