import React, { useState, useEffect } from "react";
import {
  fetchStudentCoursesReport,
  fetchStudentExams,
} from "../../../services";
import {
  AdminModal,
  AdminRow,
  AdminSpinner,
  TeacherLayout,
} from "../../../components";
import { errorLogger } from "../../../errors/errorLogger";
import { useResults } from "../../../hooks/useResults";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { StudentModalContent } from "../components/student-modal-content/StudentModalContent";

export const TeacherClassroomStudent = (props) => {
  const [isLoading, setIsLoading] = useState(null);
  const [attempts, setAttempts] = useState();
  const [studentName, setStudentName] = useState();
  const [examDetail, setExamDetail] = useState();
  const [courses, setCourses] = useState();

  const classroomId = props.routeProps.match.params.classroomId;
  const studentId = props.routeProps.match.params.studentId;
  const teacherId = useSelector((state) => state.teacher?._id);

  const { renderResults } = useResults(teacherId, studentId);

  useEffect(() => {
    if (!studentId) return;
    setIsLoading(true);
    const fetchPage = async () => {
      try {
        await fetchStudentCoursesReport(studentId).then((res) => {
          setStudentName(res.data?.studentName);
          setCourses(res.data?.courses);
        });
        await fetchStudentExams(studentId).then((res) => setAttempts(res.data));
        setIsLoading(false);
      } catch (err) {
        errorLogger(err);
      }
    };
    fetchPage();
  }, [studentId]);

  const renderModalBody = (topic) => (
    <StudentModalContent {...{ attempts, setExamDetail, topic }} />
  );

  return (
    <TeacherLayout
      backBttn={`/teacher/classrooms/${classroomId}`}
      expanded
      leftBarActive="Salones"
      topNavTitle={studentName}
    >
      {isLoading === true && (
        <div className="text-center mt-4">
          <AdminSpinner />
        </div>
      )}
      {isLoading === false && isEmpty(attempts) && (
        <div>No hay registro de exámenes.</div>
      )}
      {isLoading === false && !isEmpty(attempts) && !isEmpty(courses) && (
        <>
          {courses.map((c) => (
            <AdminRow
              key={c?.courseId}
              rowTitle={c?.courseName}
              list={{
                data: c?.courseTopics,
                modal: {
                  labelAccessor: "topicName",
                  renderModalBody: (topicSelected) =>
                    renderModalBody(topicSelected),
                },
              }}
            />
          ))}
          <AdminModal
            handleClose={() => setExamDetail()}
            show={!!examDetail}
            size="lg"
            title="Respuestas"
          >
            <div>{renderResults(examDetail)}</div>
          </AdminModal>
        </>
      )}
    </TeacherLayout>
  );
};
