import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import {
  TeacherClassroomDetail,
  TeacherClassroomExams,
  TeacherClassroomStudent,
  TeacherClassroomsPage,
  TeacherMessagesPage,
} from "../teacher-pages";

export const TeacherNavigation = () => (
  <BrowserRouter>
    <Switch>
      {/* ================= classrooms ================= */}
      <Route
        exact
        path="/teacher/classrooms"
        component={TeacherClassroomsPage}
      />
      <Route
        exact
        path="/teacher/classrooms/:classroomId"
        render={(props) => <TeacherClassroomDetail routeProps={props} />}
      />
      <Route
        exact
        path="/teacher/classrooms/exams/:classroomId"
        render={(props) => <TeacherClassroomExams routeProps={props} />}
      />
      <Route
        exact
        path="/teacher/classrooms/:classroomId/student/:studentId"
        render={(props) => <TeacherClassroomStudent routeProps={props} />}
      />
      {/* ================= students ================= */}
      {/* <Route exact path="/teacher/students" component={TeacherStudentsPage} /> */}
      {/* <Route
        exact
        path="/teacher/students/:studentId"
        render={(props) => <TeacherStudentsDetails routeProps={props} />}
      /> */}
      {/* <Route
        exact
        path="/teacher/students/exams/:studentId"
        render={(props) => <TeacherStudentExams routeProps={props} />}
      /> */}
      {/* <Route
        exact
        path="/teacher/students/chart/:studentId/:courseId"
        render={(props) => <TeacherStudentChart routeProps={props} />}
      /> */}
      {/* ================= messages ================= */}
      <Route exact path="/teacher/messages" component={TeacherMessagesPage} />
      {/* ================= redirect to teacher navigation ================= */}
      <Redirect from="*" to="/teacher/classrooms" />
    </Switch>
  </BrowserRouter>
);
