import axios from "axios";

export const fetchTeacherClassrooms = (teacherId) =>
  axios.get(`/teacherapi/classrooms/all/${teacherId}`);

export const fetchOneClassroom = (classroomId) =>
  axios.get(`/teacherapi/classrooms/${classroomId}`);

export const fetchClassroomCatalogue = (classroomId) =>
  axios.get(`/teacherapi/classrooms/catalogue/${classroomId}`);

export const fetchClassroomTopicReport = (classroomId, topicId) =>
  axios.get(`/teacherapi/classrooms/report/${classroomId}/${topicId}`);
