import React, { useEffect, useMemo, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Col, Container, Row } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import { isEmpty, isEqual } from "lodash";
import { Button } from "../../../components";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const LineChart = ({ report = [], courseName }) => {
  const [activeTopic, setActiveTopic] = useState();
  const [chart, setChart] = useState();

  useEffect(() => setActiveTopic(report[0]?.name), [report]);

  useEffect(
    () => setChart(report.filter(({ name }) => isEqual(name, activeTopic))[0]),
    [activeTopic, report]
  );

  const data = useMemo(
    () => ({
      datasetIdKey: `lineChart${activeTopic}`,
      labels: ["Examen 1", "Examen 2", "Examen 3", "Examen 4", "Examen 5"],
      datasets: [
        {
          borderColor: "#48bf84",
          borderWidth: 3,
          data: chart?.exams?.map(({ highestGrade }) => highestGrade),
          tension: 0.1,
        },
      ],
    }),
    [activeTopic, chart]
  );

  const options = useMemo(
    () => ({
      responsive: true,
      scales: {
        y: {
          min: 0,
          max: 10,
        },
      },
      elements: {
        point: {
          backgroundColor: "#48bf84",
          hoverRadius: 12,
          pointStyle: "circle",
          radius: 4,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        title: {
          color: "#0f5257",
          display: true,
          text: activeTopic,
          font: {
            size: 24,
          },
        },
        tooltip: {
          displayColors: false,
          callbacks: {
            title: (context) => {
              const label = Number(context[0]?.label?.slice(-1) || 0);
              const labelText = chart?.exams[label - 1]?.name;
              return labelText.toUpperCase();
            },
            label: (context) => {
              const label = Number(context?.label?.slice(-1) || 0);
              const labelText = chart?.exams[label - 1]?.name;
              const { highestGrade } = chart?.exams?.find((ex) =>
                isEqual(ex?.name, labelText)
              );
              return `Máxima Calif. ${highestGrade}`;
            },
            afterBody: (context) => {
              const label = Number(context[0]?.label?.slice(-1) || 0);
              const labelText = chart?.exams[label - 1]?.name;
              const { sumOfGrades, totalAttempts } = chart?.exams?.find((ex) =>
                isEqual(ex?.name, labelText)
              );
              const average = (sumOfGrades / totalAttempts)
                .toFixed(2)
                .replace(".00", "");
              return `Intentos ${totalAttempts}\nPromedio ${average}`;
            },
          },
        },
      },
    }),
    [activeTopic, chart]
  );

  const fallbackContent = (
    <div className="mt-2 mb-4">Hubo un error en la gráfica</div>
  );

  if (isEmpty(report)) return null;

  return (
    <Container className="mt-2 mb-4">
      <Row>
        <Col>
          <section className="mb-4">
            {courseName && <h4>{`${courseName}:`}</h4>}
            {report.map(({ name }, idx) => (
              <Button
                className="d-block text-left"
                isActive={isEqual(activeTopic, name)}
                isAnchor
                key={idx}
                onClick={() =>
                  !isEqual(activeTopic, name) && setActiveTopic(name)
                }
                size="sm"
              >
                {`> ${name}`}
              </Button>
            ))}
          </section>
          <Line
            {...{
              data,
              datasetIdKey: `lineChart${activeTopic}`,
              fallbackContent,
              options,
            }}
          />
        </Col>
      </Row>
    </Container>
  );
};
