import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import { bool, number, string } from "prop-types";
import { AlertModal } from "../../../components";
import { LanguageContext } from "../../../languages";

export const ScoreModal = React.memo(({ image, score, show, url }) => {
  const { displayText } = useContext(LanguageContext);

  return (
    <AlertModal image={image} show={show}>
      <h5 className="text-dark mb-3 mt-3">
        {displayText("examPage.questionsContainer.scoreModal.timeFinished")}
      </h5>
      <h1 className="display-1">{`[ ${score} ]`}</h1>
      <div className="mt-4">
        <Button
          onClick={() => (window.location.href = url)}
          className="shadow-sm genericButton"
        >
          {displayText("examPage.questionsContainer.scoreModal.back")}
        </Button>
      </div>
    </AlertModal>
  );
});

ScoreModal.propTypes = {
  image: string.isRequired,
  score: number.isRequired,
  show: bool.isRequired,
  url: string.isRequired,
};

ScoreModal.displayName = "ScoreModal";
