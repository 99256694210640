import { get, isEmpty } from "lodash";
import { array, func, string } from "prop-types";
import React, { memo, useState } from "react";
import { IconButton } from "../buttons/IconButton";
import { AdminModal } from "../modals/AdminModal";

export const ListWithModals = memo(
  ({ data, labelAccessor, renderModalBody }) => {
    const [itemSelected, setItemSelected] = useState(null);

    if (isEmpty(data) || !labelAccessor || !renderModalBody) return;

    return (
      <>
        {data.map((item, idx) => (
          <span className="d-block" key={idx}>
            {get(item, labelAccessor)}
            <IconButton
              {...{
                hoverText: "Ver detalle",
                onClick: () => setItemSelected(item),
                svg: "popup",
              }}
            />
          </span>
        ))}

        <AdminModal
          handleClose={() => setItemSelected(null)}
          show={!!itemSelected}
          size="xl"
          title={get(itemSelected, labelAccessor) || ""}
        >
          <>{renderModalBody(itemSelected)}</>
        </AdminModal>
      </>
    );
  }
);

ListWithModals.propTypes = {
  data: array,
  labelAccessor: string,
  renderModalBody: func,
};

ListWithModals.displayName = "ListWithModals";
