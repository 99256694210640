export const english = {
  nav: {
    code: {
      joinLabel: "Join Classroom",
      modalTitle: "Insert classroom code.",
      modalSubtitle: "Code",
      joinButton: "Join",
    },
    smallScreenDiv: {
      classroomHeader: "CLASSROOM",
      myUserHeader: "MY USER",
    },
    userDropdown: {
      myCourses: "My Courses",
      myMessages: "My Messages",
      signout: "Logout",
    },
  },
  dashboard: {
    coursesTitle: "Courses",
    classroomsTitle: "Classrooms",
    noCourses: "You don't have courses...",
    dashboardClassroomCard: {
      male: "Teacher:",
      female: "Teacher:",
    },
  },
  messages: {
    title: "Messages",
    empty: "You don't have messages...",
    conversationModal: {
      title: "Messages",
      sender: "Sender",
      recipient: "Recipient",
      origin: "Origin",
      date: "Date",
      onDeleteConfirm: "Are you sure you want to delete this message?",
      delete: "Delete",
      send: "Send",
    },
  },
  coursePage: {
    alert: "An error ocurred when loading the course data.",
    back: "Back to my courses",
    topics: "Topics",
    medals: "Medals",
    material: "Media",
    exams: "Exams",
    fastMode: "Freestyle",
    passed: "Approved",
    perfectScore: "Perfect score",
    blocked: "Blocked",
    underConstruction: "Under development...",
    cardContent: {
      higherGrade: "Highest grade",
      attempts: "Attempts",
    },
    levels: {
      basic: "Basic",
      basicIntermediate: "Basic-Intermediate",
      intermediate: "Intermediate",
      intermediateAdvanced: "Intermediate-Advanced",
      advanced: "Advanced",
      difficulty: "Difficulty",
    },
    grade: "Grade",
    attempts: "Attempts",
    start: "Start",
    notAvailable:
      "To unlock this exam you need to score 8 or higher in the previous exam.",
    freestyle: {
      title: "Freestyle",
      description: "Answer as many questions as you can in {1} minutes.",
      description2:
        "The top 10 higher scores will be exhibited in the roll of honor.",
      noAttempts: "You haven't tried this exam yet",
      lastAttempt: "Last Attempt",
      duration: "Duration",
      higherScore: "Highest grade",
      points: "Pointss",
      numberOfAttempts: "Number of attempts",
      attempts: "Attempts",
      start: "Start",
    },
    helpModal: {
      help: "Help",
      message:
        "Do you need help? Use the next box to send a message to your teacher.",
      chooseTeacher: "Choose teacher:",
      messageSent: "Message sent.",
      cancel: "Cancel",
      send: "Send",
      required: "Required",
    },
    blockedMedal: "Blocked medal",
  },
  examPage: {
    alert: "An error ocurred. Contact your teacher.",
    questionsContainer: {
      alert:
        "An error ocurred in the server, the grade couldn't be registered.",
      timer: {
        title: "Time left",
        minutes: "Minutes",
        seconds: "Seconds",
      },
      freestylePoints: "Points",
      progress: "Progress",
      point: "Point",
      points: "Points",
      buttonFinish: "Finish",
      buttonNext: "Next",
      exit: {
        message:
          "Are you sure you want to exit the exam? Your progress will be removed.",
        cancel: "Cancel",
        abandon: "Exit",
      },
      timeOutModal: {
        title: "Time is over.",
        back: "Back to the course",
      },
      scoreModal: {
        timeFinished: "Time is over, your final score is:",
        back: "Back to the course",
      },
      correct: "Correct",
      incorrect: "Incorrect",
    },
  },
  resultsPage: {
    back: "Back",
    grade: {
      score: "Your grade is...",
      corrects: "CORRECTS",
      incorrects: "INCORRECTS",
    },
    results: {
      perfectGrade: "Perfect Score!",
      crown: "You have received a crown for your performance",
      satisfied: "Satisfactory",
      veryGood: "Good Job!",
      notSatisfied: "Try Again",
      notSatisfiedMessage: "You need a minimum of {1} to approve this exam",
    },
    unlocked: {
      newExam: "New Exam!",
      msg1: "The exam",
      msg2: "has been unlocked.",
      congratulations: "Congratulations!",
      concluded: "You have completed the topic",
      fastMode: "Freestyle",
    },
    myResults: {
      yourAnswers: "Your answers were...",
      correct: "CORRECT",
      yourAnswer: "Your answer:",
      correctAnswer: "Correct answer",
      clickToCheck: "Click here to check the correct answer",
      incorrect: "INCORRECT",
    },
  },
  noMatch: {
    title: "Error, the page you are looking for doesn't exist.",
    msg1: "Back to our",
    msg2: "landing page",
  },
  freestylePage: {
    alert: "An error ocurred whe loading this exam. Contact your teacher.",
  },
  goTo: "Go to",
  approved: "Approved",
};
