import React from "react";
import { StudentLayout } from "../../components";

import styles from "./legal.module.scss";

export const TermsAndConditions = () => (
  <StudentLayout
    canTranslate={false}
    isContainer={false}
    hasScrollButton={true}
  >
    <section className={styles.wrapper}>
      <div className="text-center mb-4">
        <h1 className={styles.title}>Términos y Condiciones</h1>
      </div>
      <h5>Términos generales</h5>
      <p>
        Al adquirir una licencia y hacer uso del contenido de la plataforma
        educativa digital MeXmáticas, usted confirma que está de acuerdo y
        sujeto a los términos de servicio contenidos en los Términos y
        condiciones que se describen a continuación. Estos términos se aplican a
        todo el sitio web y a cualquier correo electrónico u otro tipo de
        comunicación entre usted y MeXmáticas. Bajo ninguna circunstancia el
        equipo de MeXmáticas será responsable de ningún daño directo, indirecto,
        especial, incidental o consecuente, que incluye, entre otros, la pérdida
        de datos o ganancias que surjan del uso o la incapacidad de usar, los
        materiales de este sitio, incluso si el equipo de MeXmáticas o un
        representante autorizado han sido informados de la posibilidad de tales
        daños. Si su uso de materiales de este sitio resulta en la necesidad de
        servicio, reparación o corrección de equipos o datos, usted asume los
        costos de los mismos. MeXmáticas no será responsable de ningún resultado
        que pueda ocurrir durante el curso del uso de nuestros recursos.
        Nosreservamos el derecho de cambiar los precios y revisar la política de
        uso de recursos en cualquier momento.
      </p>
      <h5>Licencia</h5>
      <p>
        MeXmáticas le otorga una licencia revocable, no exclusiva,
        intransferible y limitada para descargar, instalar y usar la plataforma
        estrictamente de acuerdo con los términos de este Acuerdo. Estos
        Términos y condiciones son un contrato entre usted y MeXmáticas
        (referidos en estos Términos y condiciones como "MeXmáticas",
        "nosotros", o "nuestro"), el proveedor del sitio web de MeXmáticas y los
        servicios accesibles desde el sitio web de MeXmáticas (que se denominan
        colectivamente en estos Términos y condiciones como el "Servicio de
        MeXmáticas"). Usted acepta estar sujeto a estos Términos y condiciones.
        Si no está de acuerdo con estos Términos y condiciones, no utilice el
        Servicio MeXmáticas. En estos Términos y condiciones, "usted" se refiere
        tanto a usted como individuo como a la entidad que representa. Si viola
        cualquiera de estos Términos y condiciones, nos reservamos el derecho de
        cancelar su cuenta o bloquear el acceso a su cuenta sin previo aviso.
      </p>
      <h5>Definiciones y términos clave</h5>
      <p>
        Para ayudar a explicar las cosas de la manera más clara posible en estos
        Términos y Condiciones, cada vez que se hace referencia a cualquiera de
        estos términos, se definen estrictamente como:
      </p>
      <ul>
        <li>
          Cookie: pequeña cantidad de datos generados por un sitio web y
          guardados por su navegador web. Se utiliza para identificar su
          navegador, proporcionar análisis, recordar información sobre usted,
          como su preferencia de idioma o información de inicio de sesión.
        </li>
        <li>
          Compañía: cuando estos Términos y Condiciones mencionan "Compañía",
          "nosotros", "nos" o "nuestro", se refiere a MeXmáticas, que es
          responsable de su información en virtud de estos Términos y
          Condiciones.
        </li>
        <li>
          Plataforma: sitio web de Internet, aplicación web o aplicación digital
          de cara al público de MeXmáticas.
        </li>
        <li>
          País: donde se encuentra MeXmáticas o los propietarios / fundadores de
          MeXmáticas, en este caso es México
        </li>
        <li>
          Dispositivo: cualquier dispositivo conectado a Internet, como un
          teléfono, tablet, computadora o cualquier otro dispositivo que se
          pueda usar para visitar MeXmáticas y usar los servicios.
        </li>
        <li>
          Servicio: se refiere al servicio brindado por MeXmáticas como se
          describe en los términos relativos (si están disponibles) y en esta
          plataforma.
        </li>
        <li>
          Terceros: se refiere a anunciantes, patrocinadores de concursos,
          socios promocionales y de marketing, y otros que brindan nuestro
          contenido o cuyos productos o servicios que creemos que pueden
          interesarle.
        </li>
        <li>
          Sitio web: el sitio de MeXmáticas, al que se puede acceder a través de
          esta URL:{" "}
          <a href="https://www.mexmaticas.com.mx">
            https://www.mexmaticas.com.mx
          </a>
        </li>
        <li>
          Usted: una persona o entidad que está registrada con MeXmáticas para
          utilizar los Servicios.
        </li>
      </ul>
      <h5>Restricciones</h5>
      <p>Usted acepta no hacerlo y no permitirá que otros:</p>
      <ul>
        <li>
          Licenciar, vender, alquilar, arrendar, asignar, distribuir,
          transmitir, alojar, subcontratar, divulgar o explotar comercialmente
          la plataforma o poner la plataforma a disposición de terceros.
        </li>
        <li>
          Modificar, realizar trabajos derivados, desensamblar, descifrar,
          realizar una compilación inversa o realizar ingeniería inversa de
          cualquier parte de la plataforma.
        </li>
        <li>
          Eliminar, alterar u ocultar cualquier aviso de propiedad (incluido
          cualquier aviso de derechos de autor o marca registrada) de sus
          afiliados, socios, proveedores o licenciatarios de la plataforma.
        </li>
      </ul>
      <h5>Política de Devolución y Reembolso</h5>
      <p>
        Gracias por colaborar con MeXmáticas. Apreciamos su compromiso con la
        educación.
      </p>
      <p>
        Al igual que con cualquier experiencia con adquisicion de licencias,
        existen términos y condiciones que se aplican a las transacciones en
        MeXmáticas. Seremos tan breves como lo permitan nuestros abogados. Lo
        principal que debe recordar es que al adquiri nuestra licencia de uso en
        MeXmáticas, acepta los términos junto con la Política de privacidad de
        MeXmáticas.
      </p>
      <p>
        Si por alguna razón no está completamente satisfecho con algún bien o
        servicio que le brindamos, no dude en contactarnos y discutiremos
        cualquiera de los problemas que esté atravesando con nuestro servicio.
      </p>
      <h5>Tus sugerencias</h5>
      <p>
        Cualquier, comentario, idea, mejora o sugerencia (colectivamente,
        "Sugerencias") que usted proporcione a MeXmáticas con respecto a la
        plataforma seguirá siendo propiedad única y exclusiva de MeXmáticas.
      </p>
      <p>
        MeXmáticas tendrá la libertad de usar, copiar, modificar, publicar o
        redistribuir las Sugerencias para cualquier propósito y de cualquier
        manera sin ningún crédito o compensación para usted.
      </p>
      <h5>Tu consentimiento</h5>
      <p>
        Hemos actualizado nuestros Términos y condiciones para brindarle total
        transparencia sobre lo que se establece cuando visita nuestro sitio y
        cómo se utiliza. Al utilizar nuestra plataforma, registrar una cuenta o
        realizar una compra, por la presente acepta nuestros Términos y
        condiciones.
      </p>
      <h5>Enlaces a otros Sitios Web</h5>
      <p>
        Estos Términos y Condiciones se aplican solo a los Servicios. Los
        Servicios pueden contener enlaces a otros sitios web que MeXmáticas no
        opera ni controla. No somos responsables por el contenido, la precisión
        o las opiniones expresadas en dichos sitios web, y dichos sitios web no
        son investigados, monitoreados o verificados por nuestra precisión o
        integridad. Recuerde que cuando utiliza un enlace para ir de los
        Servicios a otro sitio web, nuestros Términos y condiciones dejan de
        estar vigentes. Su navegación e interacción en cualquier otro sitio web,
        incluidos aquellos que tienen un enlace en nuestra plataforma, están
        sujetos a las propias reglas y políticas de ese sitio web. Dichos
        terceros pueden utilizar sus propias cookies u otros métodos para
        recopilar información sobre usted.
      </p>
      <h5>Cookies</h5>
      <p>
        MeXmáticas utiliza "cookies" para identificar las áreas de nuestro sitio
        web que ha visitado. Una cookie es una pequeña porción de datos que su
        navegador web almacena en su computadora o dispositivo móvil. Usamos
        cookies para mejorar el rendimiento y la funcionalidad de nuestra
        plataforma, pero no son esenciales para su uso. Sin embargo, sin estas
        cookies, es posible que ciertas funciones, como los videos, no estén
        disponibles o se le solicitará que ingrese sus datos de inicio de sesión
        cada vez que visite la plataforma, ya que no podríamos recordar que
        había iniciado sesión anteriormente. La mayoría de los navegadores web
        se pueden configurar para desactivar el uso de cookies. Sin embargo, si
        desactiva las cookies, es posible que no pueda acceder a la
        funcionalidad de nuestro sitio web correctamente o en absoluto. Nunca
        colocamos información de identificación personal en cookies.
      </p>
      <h5>Cambios en nuestros Términos y Condiciones</h5>
      <p>
        Usted reconoce y acepta que MeXmáticas puede dejar de brindarle (de
        forma permanente o temporal) el Servicio (o cualquier función dentro del
        Servicio) a usted o a los usuarios en general, a discreción exclusiva de
        MeXmáticas, sin previo aviso. Puede dejar de utilizar el Servicio en
        cualquier momento. No es necesario que informe específicamente a
        MeXmáticas cuando deje de usar el Servicio. Usted reconoce y acepta que
        si MeXmáticas deshabilita el acceso a su cuenta, es posible que no pueda
        acceder al Servicio, los detalles de su cuenta o cualquier archivo u
        otro material contenido en su cuenta.
      </p>
      <p>
        Si decidimos cambiar nuestros Términos y condiciones, publicaremos esos
        cambios en esta página y / o actualizaremos la fecha de modificación de
        los Términos y condiciones a continuación.
      </p>
      <h5>Modificaciones y actualizaciones a nuestra plataforma</h5>
      <p>
        MeXmáticas puede, de vez en cuando, proporcionar mejoras a las
        características / funcionalidad de la plataforma, que pueden incluir
        parches, corrección de errores, actualizaciones, mejoras y otras
        modificaciones ("Actualizaciones"). Dichos cambios serán notificados a
        la institución que haya adquirido la licencia de uso con anticipación si
        es posible.
      </p>
      <p>
        Todas las modificaciones a nuestra plataforma por motivos de mejora a la
        misma o corrección de errores (bugs o crash) seran resueltos en un lapso
        no mayor a 10 días.
      </p>
      <p>
        Las actualizaciones pueden modificar o eliminar ciertas características
        y / o funcionalidades de la plataforma. Usted acepta que MeXmáticas no
        tiene la obligación de (i) proporcionar Actualizaciones, o (ii)
        continuar proporcionándole o habilitando características y / o
        funcionalidades particulares de la plataforma.
      </p>
      <p>
        Además, acepta que todas las Actualizaciones (i) se considerarán una
        parte integral de la plataforma y (ii) estarán sujetas a los términos y
        condiciones de este Acuerdo.
      </p>
      <h5>Servicios de Terceros</h5>
      <p>
        Podemos mostrar, incluir o poner a disposición contenido de terceros
        (incluidos datos, información, aplicaciones y otros servicios de
        productos) o proporcionar enlaces a sitios web o servicios de terceros
        ("Servicios de terceros").
      </p>
      <p>
        Usted reconoce y acepta que MeXmáticas no será responsable de ningún
        Servicio de terceros, incluida su precisión, integridad, puntualidad,
        validez, cumplimiento de los derechos de autor, legalidad, decencia,
        calidad o cualquier otro aspecto de los mismos. MeXmáticas no asume ni
        tendrá ninguna obligación o responsabilidad ante usted o cualquier otra
        persona o entidad por los Servicios de terceros.
      </p>
      <p>
        Los Servicios de terceros y los enlaces a los mismos se brindan
        únicamente para su conveniencia y usted accede a ellos y los usa
        completamente bajo su propio riesgo y sujeto a los términos y
        condiciones de dichos terceros.
      </p>
      <h5>Duración y Terminación</h5>
      <p>
        Este Acuerdo permanecerá en vigor hasta que el contrato previamente
        firmado por la institución y MeXmáticas haya concluido. El periodo del
        mismo dependerá de la fecha vigente estipulada dentro del mismo
        contrato.
      </p>
      <h5>Aviso de infracción de Derechos de Autor</h5>
      <p>
        Si usted es propietario de los derechos de autor o el agente de dicho
        propietario y cree que cualquier material de nuestra plataforma
        constituye una infracción de sus derechos de autor, comuníquese con
        nosotros y proporcione la siguiente información: (a) una firma física o
        electrónica del propietario de los derechos de autor o una persona
        autorizada para actuar en su nombre; (b) identificación del material que
        se alega infringe; (c) su información de contacto, incluida su
        dirección, número de teléfono y un correo electrónico; (d) una
        declaración suya de que cree de buena fe que el uso del material no está
        autorizado por los propietarios de los derechos de autor; y (e) la
        declaración de que la información en la notificación es precisa y, bajo
        pena de perjurio, usted está autorizado a actuar en nombre del
        propietario.
      </p>
      <h5>Indemnización</h5>
      <p>
        Usted acepta indemnizar y eximir de responsabilidad a MeXmáticas y a sus
        empresas matrices, subsidiarias, afiliadas, funcionarios, empleados,
        agentes, socios y otorgantes de licencias (si corresponde) de cualquier
        reclamo o demanda, incluidos los honorarios razonables de abogados,
        debido a que surja de su: (a) uso de la plataforma; (b) violación de
        este Acuerdo o cualquier ley o reglamento; o (c) violación de cualquier
        derecho de un tercero.
      </p>
      <h5>Sin garantías</h5>
      <p>
        La plataforma se le proporciona "TAL CUAL" y "SEGÚN DISPONIBILIDAD" y
        con todas las fallas y defectos sin garantía de ningún tipo. En la
        medida máxima permitida por la ley aplicable, MeXmáticas, en su propio
        nombre y en nombre de sus afiliados y sus respectivos licenciantes y
        proveedores de servicios, renuncia expresamente a todas las garantías,
        ya sean expresas, implícitas, legales o de otro tipo, con con respecto a
        la plataforma, incluidas todas las garantías implícitas de
        comerciabilidad, idoneidad para un propósito particular, título y no
        infracción, y garantías que puedan surgir del curso del trato, el curso
        del desempeño, el uso o la práctica comercial. Sin limitación a lo
        anterior, MeXmáticas no ofrece garantía ni compromiso, y no hace ninguna
        representación de ningún tipo de que la plataforma cumplirá con sus
        requisitos, logrará los resultados previstos, será compatible o
        funcionará con cualquier otro software, sitios web, sistemas o
        servicios, operen sin interrupciones, cumplan con los estándares de
        rendimiento o confiabilidad o que no tengan errores o que cualquier
        error o defecto puede o será corregido.
      </p>
      <p>
        Sin limitar lo anterior, ni MeXmáticas ni ningún proveedor de MeXmáticas
        hace ninguna representación o garantía de ningún tipo, expresa o
        implícita: (i) en cuanto al funcionamiento o disponibilidad de la
        plataforma, o la información, contenido y materiales o productos
        incluidos en el mismo; (ii) que la plataforma será ininterrumpida o
        libre de errores; (iii) en cuanto a la precisión, confiabilidad o
        vigencia de cualquier información o contenido proporcionado a través de
        la plataforma; o (iv) que la plataforma, sus servidores, el contenido o
        los correos electrónicos enviados desde o en nombre de MeXmáticas están
        libres de virus, scripts, troyanos, gusanos, malware, bombas de tiempo u
        otros componentes nocivos.
      </p>
      <p>
        Algunas jurisdicciones no permiten la exclusión o las limitaciones de
        las garantías implícitas o las limitaciones de los derechos legales
        aplicables de un consumidor, por lo que algunas o todas las exclusiones
        y limitaciones anteriores pueden no aplicarse en su caso.
      </p>
      <h5>Limitación de Responsabilidad</h5>
      <p>
        Sin perjuicio de los daños en los que pueda incurrir, la responsabilidad
        total de MeXmáticas y cualquiera de sus proveedores en virtud de
        cualquier disposición de este Acuerdo y su recurso exclusivo por todo lo
        anterior se limitará al monto realmente pagado por usted para la
        plataforma.
      </p>
      <p>
        En la máxima medida permitida por la ley aplicable, en ningún caso
        MeXmáticas o sus proveedores serán responsables de ningún daño especial,
        incidental, indirecto o consecuente de ningún tipo (incluidos, entre
        otros, daños por lucro cesante, por pérdida de datos u otra información,
        por interrupción del negocio, por lesiones personales, por la pérdida de
        privacidad que surja de alguna manera relacionada con el uso o la
        imposibilidad de usar la plataforma, software de terceros y / o -
        hardware de terceros utilizado con la plataforma, o de otro modo en
        relación con cualquier disposición de este Acuerdo), incluso si
        MeXmáticas o cualquier proveedor ha sido informado de la posibilidad de
        tales daños e incluso si el recurso no cumple con su propósito esencial.
      </p>
      <p>
        Algunos estados / jurisdicciones no permiten la exclusión o limitación
        de daños incidentales o consecuentes, por lo que es posible que la
        limitación o exclusión anterior no se aplique en su caso.
      </p>
      <h5>Divisibilidad</h5>
      <p>
        Si alguna disposición de este Acuerdo se considera inaplicable o
        inválida, dicha disposición se cambiará e interpretará para lograr los
        objetivos de dicha disposición en la mayor medida posible según la ley
        aplicable y las disposiciones restantes continuarán en pleno vigor y
        efecto.
      </p>
      <p>
        Este Acuerdo, junto con la Política de privacidad y cualquier otro aviso
        legal publicado por MeXmáticas en los Servicios, constituirá el acuerdo
        completo entre usted y MeXmáticas con respecto a los Servicios. Si
        alguna disposición de este Acuerdo es considerada inválida por un
        tribunal de jurisdicción competente, la invalidez de dicha disposición
        no afectará la validez de las disposiciones restantes de este Acuerdo,
        que permanecerán en pleno vigor y efecto. Ninguna renuncia a cualquier
        término de este Acuerdo se considerará una renuncia adicional o continua
        de dicho término o cualquier otro término, y el hecho de que MeXmáticas
        no haga valer ningún derecho o disposición en virtud de este Acuerdo no
        constituirá una renuncia a dicho derecho. o provisión. USTED Y
        MeXmáticas ACEPTAN QUE CUALQUIER CAUSA DE ACCIÓN QUE SURJA O ESTÉ
        RELACIONADA CON LOS SERVICIOS DEBE COMENZAR DENTRO DE UN (1) AÑO DESPUÉS
        DE QUE LA CAUSA DE ACCIÓN SE ACUERDA. DE LO CONTRARIO, DICHA CAUSA DE
        ACCIÓN ESTÁ PERMANENTEMENTE PROHIBIDA.
      </p>
      <h5>Renuncia</h5>
      <p>
        Salvo lo dispuesto en el presente, el hecho de no ejercer un derecho o
        exigir el cumplimiento de una obligación en virtud de este Acuerdo no
        afectará la capacidad de una de las partes para ejercer dicho derecho o
        requerir dicho cumplimiento en cualquier momento posterior, ni
        constituirá la renuncia a una infracción. cualquier incumplimiento
        posterior.
      </p>
      <p>
        Ninguna falla en el ejercicio, ni demora en el ejercicio, por parte de
        cualquiera de las partes, de cualquier derecho o poder bajo este Acuerdo
        operará como una renuncia a ese derecho o poder. El ejercicio único o
        parcial de cualquier derecho o poder en virtud de este Acuerdo tampoco
        impedirá el ejercicio posterior de ese o cualquier otro derecho otorgado
        en este documento. En caso de conflicto entre este Acuerdo y cualquier
        compra u otros términos aplicables, regirán los términos de este
        Acuerdo.
      </p>
      <h5>Enmiendas a este Acuerdo</h5>
      <p>
        MeXmáticas se reserva el derecho, a su entera discreción, de modificar o
        reemplazar este Acuerdo en cualquier momento. Si una revisión es
        importante, proporcionaremos un aviso de al menos 30 días antes de que
        entren en vigencia los nuevos términos. Lo que constituye un cambio
        material se determinará a nuestro exclusivo criterio.
      </p>
      <p>
        Si continúa accediendo o utilizando nuestra plataforma después de que
        las revisiones entren en vigencia, usted acepta estar sujeto a los
        términos revisados. Si no está de acuerdo con los nuevos términos, ya no
        está autorizado para usar MeXmáticas.
      </p>
      <h5>Acuerdo completo</h5>
      <p>
        El Acuerdo constituye el acuerdo completo entre usted y MeXmáticas con
        respecto a su uso de la plataforma y reemplaza todos los acuerdos
        escritos u orales anteriores y contemporáneos entre usted y MeXmáticas.
      </p>
      <p>
        Es posible que esté sujeto a términos y condiciones adicionales que se
        aplican cuando usa o compra otros servicios de MeXmáticas, que
        MeXmáticas le proporcionará en el momento de dicho uso o compra.
      </p>
      <h5>Actualizaciones de nuestros Términos</h5>
      <p>
        Podemos cambiar nuestro Servicio y nuestras políticas, y es posible que
        debamos realizar cambios en estos Términos para que reflejen con
        precisión nuestro Servicio y nuestras políticas. A menos que la ley
        exija lo contrario, le notificaremos (por ejemplo, a través de nuestro
        Servicio) antes de realizar cambios en estos Términos y le daremos la
        oportunidad de revisarlos antes de que entren en vigencia. Luego, si
        continúa utilizando el Servicio, estará sujeto a los Términos
        actualizados. Si no desea aceptar estos o alguno de los Términos
        actualizados, puede eliminar su cuenta.
      </p>
      <h5>Propiedad intelectual</h5>
      <p>
        La plataforma y todo su contenido, características y funcionalidad (que
        incluyen, entre otros, toda la información, software, texto, pantallas,
        imágenes, video y audio, y el diseño, selección y disposición de los
        mismos), son propiedad de MeXmáticas, sus licenciantes u otros
        proveedores de dicho material y están protegidos por México y leyes
        internacionales de derechos de autor, marcas registradas, patentes,
        secretos comerciales y otras leyes de propiedad intelectual o derechos
        de propiedad. El material no puede ser copiado, modificado, reproducido,
        descargado o distribuido de ninguna manera, en su totalidad o en parte,
        sin el permiso previo expreso por escrito de MeXmáticas, a menos que y
        excepto que se indique expresamente en estos Términos y Condiciones. Se
        prohíbe cualquier uso no autorizado del material.
      </p>
      <h5>Acuerdo de Arbitraje</h5>
      <p>
        Esta sección se aplica a cualquier disputa, EXCEPTO QUE NO INCLUYE UNA
        DISPUTA RELACIONADA CON RECLAMOS POR RECURSOS INJUNTIVOS O EQUITATIVOS
        CON RESPECTO A LA EJECUCIÓN O VALIDEZ DE SUS DERECHOS DE PROPIEDAD
        INTELECTUAL O DE MeXmáticas. El término "disputa" significa cualquier
        disputa, acción u otra controversia entre usted y MeXmáticas en relación
        con los Servicios o este acuerdo, ya sea en contrato, garantía, agravio,
        estatuto, regulación, ordenanza o cualquier otra base legal o
        equitativa. "Disputa" tendrá el significado más amplio posible permitido
        por la ley.
      </p>
      <h5>Aviso de Disputa</h5>
      <p>
        En el caso de una disputa, usted o MeXmáticas deben darle al otro un
        Aviso de Disputa, que es una declaración escrita que establece el
        nombre, la dirección y la información de contacto de la parte que la
        proporcionó, los hechos que dieron lugar a la disputa y la reparación
        solicitada. Debe enviar cualquier Aviso de disputa por correo
        electrónico a:{" "}
        <a href="mailto: contacto@mexmaticas.com.mx">
          contacto@mexmaticas.com.mx
        </a>
        . MeXmáticas le enviará cualquier Aviso de disputa por correo a su
        dirección si la tenemos, o de otra manera a su dirección de correo
        electrónico. Usted y MeXmáticas intentarán resolver cualquier disputa
        mediante una negociación informal dentro de los sesenta (60) días a
        partir de la fecha en que se envíe la Notificación de disputa. Después
        de sesenta (60) días, usted o MeXmáticas pueden comenzar el arbitraje.
      </p>
      <h5>Arbitraje Obligatorio</h5>
      <p>
        Si usted y MeXmáticas no resuelven ninguna disputa mediante una
        negociación informal, cualquier otro esfuerzo para resolver la disputa
        se llevará a cabo exclusivamente mediante arbitraje vinculante como se
        describe en esta sección. Está renunciando al derecho de litigar (o
        participar como parte o miembro de la clase) todas las disputas en la
        corte ante un juez o jurado. La disputa se resolverá mediante arbitraje
        vinculante de acuerdo con las reglas de arbitraje comercial de la
        Asociación Americana de Arbitraje. Cualquiera de las partes puede buscar
        medidas cautelares provisionales o preliminares de cualquier tribunal de
        jurisdicción competente, según sea necesario para proteger los derechos
        o la propiedad de las partes en espera de la finalización del arbitraje.
        Todos y cada uno de los costos, honorarios y gastos legales, contables y
        de otro tipo en los que incurra la parte ganadora correrán a cargo de la
        parte no ganadora.
      </p>
      <h5>Diverso</h5>
      <p>
        Si por alguna razón un tribunal de jurisdicción competente determina que
        alguna disposición o parte de estos Términos y condiciones no se puede
        hacer cumplir, el resto de estos Términos y condiciones continuará en
        pleno vigor y efecto. Cualquier renuncia a cualquier disposición de
        estos Términos y condiciones será efectiva solo si está por escrito y
        firmada por un representante autorizado de MeXmáticas. MeXmáticas tendrá
        derecho a una medida cautelar u otra compensación equitativa (sin la
        obligación de depositar ninguna fianza o garantía) en caso de
        incumplimiento anticipado por su parte. MeXmáticas opera y controla el
        Servicio MeXmáticas desde sus oficinas en México. El Servicio no está
        destinado a ser distribuido ni utilizado por ninguna persona o entidad
        en ninguna jurisdicción o país donde dicha distribución o uso sea
        contrario a la ley o regulación. En consecuencia, las personas que
        eligen acceder al Servicio MeXmáticas desde otras ubicaciones lo hacen
        por iniciativa propia y son las únicas responsables del cumplimiento de
        las leyes locales, en la medida en que las leyes locales sean
        aplicables. Estos Términos y condiciones (que incluyen e incorporan la
        Política de privacidad de MeXmáticas) contienen el entendimiento
        completo y reemplazan todos los entendimientos previos entre usted y
        MeXmáticas con respecto a su tema, y ??usted no puede cambiarlos ni
        modificarlos. Los títulos de las secciones que se utilizan en este
        Acuerdo son solo por conveniencia y no se les dará ninguna importancia
        legal.
      </p>
      <h5>Descargo de Responsabilidad</h5>
      <p>
        MeXmáticas no es responsable de ningún contenido, código o cualquier
        otra imprecisión.
      </p>
      <p>MeXmáticas no ofrece garantías.</p>
      <p>
        En ningún caso MeXmáticas será responsable de ningún daño especial,
        directo, indirecto, consecuente o incidental o de cualquier daño, ya sea
        en una acción contractual, negligencia u otro agravio, que surja de o en
        conexión con el uso del Servicio o el contenido del Servicio. MeXmáticas
        se reserva el derecho de realizar adiciones, eliminaciones o
        modificaciones al contenido del Servicio en cualquier momento sin previo
        aviso.
      </p>
      <p>
        El Servicio MeXmáticas y su contenido se proporcionan "tal cual" y
        "según esté disponible" sin ninguna garantía o representación de ningún
        tipo, ya sea expresa o implícita. MeXmáticas es un distribuidor y no un
        editor del contenido proporcionado por terceros; como tal, MeXmáticas no
        ejerce ningún control editorial sobre dicho contenido y no ofrece
        ninguna garantía o representación en cuanto a la precisión,
        confiabilidad o vigencia de cualquier información, contenido, servicio o
        mercancía proporcionada o accesible a través del Servicio MeXmáticas.
        Sin limitar lo anterior, MeXmáticas renuncia específicamente a todas las
        garantías y representaciones en cualquier contenido transmitido en
        conexión con el Servicio MeXmáticas o en sitios que pueden aparecer como
        enlaces en el Servicio MeXmáticas, o en los productos proporcionados
        como parte o en relación con el Servicio MeXmáticas, incluidas, entre
        otras, las garantías de comerciabilidad, idoneidad para un propósito
        particular o no infracción de derechos de terceros. Ningún consejo oral
        o información escrita proporcionada por MeXmáticas o cualquiera de sus
        afiliados, empleados, funcionarios, directores, agentes o similares
        creará una garantía. La información sobre precios y disponibilidad está
        sujeta a cambios sin previo aviso. Sin limitar lo anterior, MeXmáticas
        no garantiza que el Servicio de MeXmáticas sea ininterrumpido, sin
        corrupción, oportuno o sin errores.
      </p>
      <h5>Contáctenos</h5>
      <p>
        No dude en contactarnos a{" "}
        <a href="mailto: contacto@mexmaticas.com.mx">
          contacto@mexmaticas.com.mx
        </a>{" "}
        si tiene alguna pregunta.
      </p>
      <p className="text-center">
        <small>
          <i>Última actualización: 04/02/2023</i>
        </small>
      </p>
    </section>
  </StudentLayout>
);
