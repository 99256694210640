import React, { useContext } from "react";
import {
  Card as BootstrapCard,
  ListGroup,
  ListGroupItem,
} from "react-bootstrap";
import { object, string } from "prop-types";
import { useDispatch } from "react-redux";
import * as courseActions from "../../../redux/actions/course";
import { Card } from "../../../components";
import cn from "classnames";
import { LanguageContext } from "../../../languages";

import styles from "./dashboardcoursecard.module.scss";

export const DashboardCourseCard = React.memo(({ course, imageName }) => {
  const dispatch = useDispatch();

  const handleClickLink = (event, courseId, courseName, topicName) => {
    event.preventDefault();
    event.stopPropagation();
    dispatch(courseActions.setCourse({ _id: courseId, name: courseName }));
    window.location.href = topicName ? `/course/#${topicName}` : "/course";
  };

  const { displayText } = useContext(LanguageContext);

  return (
    <Card
      {...{
        hoverText: `${displayText("goTo")} ${course.name}`,
        imageName,
        onClick: (event) => handleClickLink(event, course._id, course.name),
        title: course.name,
      }}
    >
      <BootstrapCard.Text className="mb-2">
        {course.description &&
          course.description.split("\\n").map((c) => {
            return (
              <span key={c} className="d-block text-muted">
                {String(c).trim()}
              </span>
            );
          })}
      </BootstrapCard.Text>
      <ListGroup className="list-group-flush">
        {course.topics
          .sort((a, b) => a.topicOrderNumber - b.topicOrderNumber)
          .map((ct) => (
            <ListGroupItem
              key={ct._id}
              className={cn(
                "d-flex flex-row align-items-center bg-transparent px-1 py-2 rounded",
                styles.listGroupItem
              )}
              onClick={(event) =>
                handleClickLink(event, course._id, course.name, ct.name)
              }
            >
              <span
                className={styles.link}
                title={`${displayText("goTo")} ${ct.name}`}
              >
                <strong>
                  <i
                    className="fas fa-location-arrow mr-2"
                    style={{ fontSize: "13px" }}
                  />
                  {ct.name}
                </strong>
              </span>
              {ct.hasReward ? (
                <i
                  className="fas fa-medal text-warning ml-2"
                  title={displayText("approved")}
                />
              ) : null}
            </ListGroupItem>
          ))}
      </ListGroup>
    </Card>
  );
});

DashboardCourseCard.propTypes = {
  course: object.isRequired,
  imageName: string,
};

DashboardCourseCard.displayName = "DashboardCourseCard";
