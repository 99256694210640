import React, { useContext } from "react";
import { array } from "prop-types";
import { Image } from "react-bootstrap";
import { MedalObtained } from ".";
import { LanguageContext } from "../../../languages";

export const MedalTable = ({ rewards }) => {
  const { displayText } = useContext(LanguageContext);

  return (
    <div className="text-center">
      {rewards.map((r) =>
        r.hasReward ? (
          <MedalObtained key={r.medalName} reward={r} />
        ) : (
          <Image
            className="my-1 mx-3"
            height="80"
            key={r.medalName}
            src="/images/emptyMedal.png"
            style={{ opacity: 0.3 }}
            title={displayText("coursePage.blockedMedal")}
            width="80"
          />
        )
      )}
    </div>
  );
};

MedalTable.propTypes = {
  rewards: array.isRequired,
};
