import React, { useCallback, useEffect, useMemo, useState } from "react";
import { isEmpty, isEqual } from "lodash";
import { Button, StudentLayout } from "../../components";
import { errorLogger } from "../../errors/errorLogger";
import { fetchLandingPageCourses } from "../../services";
import {
  Col,
  Container,
  Image,
  Jumbotron,
  ListGroup,
  Row,
  Spinner,
  Tab,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { logoutStudent } from "../../redux/actions/student";
import { logoutTeacher } from "../../redux/actions/teacher";
import { clearAdminData } from "../../redux/actions/admin";
import { clearCourse } from "../../redux/actions/course";
import { clearExam } from "../../redux/actions/exam";
import { clearPurchase } from "../../redux/actions/purchase";
import { HomeCard } from "./components/HomeCard";
import cn from "classnames";
import Fade from "react-reveal/Fade";
import { mexmaticasDivider } from "../../student-pages/course/coursepage.module.scss";

import styles from "./LandingPage.module.scss";

export const LandingPage = () => {
  const [courseSelected, setCourseSelected] = useState(0);
  const [courses, setCourses] = useState();

  const dispatch = useDispatch();

  const reduxAdminData = useSelector((state) => state.admin);
  const reduxCourse = useSelector((state) => state.course);
  const reduxExam = useSelector((state) => state.exam);
  const reduxPurchase = useSelector((state) => state.purchase);
  const reduxStudent = useSelector((state) => state.student);
  const reduxTeacher = useSelector((state) => state.teacher);

  const emptyCourses = useMemo(() => isEmpty(courses), [courses]);

  useEffect(() => {
    if (!emptyCourses) return;
    fetchLandingPageCourses()
      .then(({ data: _courses }) => setCourses(_courses))
      .catch((err) => errorLogger(err));
  }, [emptyCourses]);

  const clearReduxStore = useCallback(() => {
    if (reduxAdminData) dispatch(clearAdminData());
    if (reduxCourse) dispatch(clearCourse());
    if (reduxExam) dispatch(clearExam());
    if (reduxPurchase) dispatch(clearPurchase());
    if (reduxStudent) dispatch(logoutStudent());
    if (reduxTeacher) dispatch(logoutTeacher());
  }, [
    dispatch,
    reduxAdminData,
    reduxCourse,
    reduxExam,
    reduxPurchase,
    reduxStudent,
    reduxTeacher,
  ]);

  useEffect(() => clearReduxStore(), [clearReduxStore]);

  return (
    <StudentLayout canTranslate={false} hasScrollButton isContainer={false}>
      <Jumbotron fluid className={cn(styles.jumbotron, "shadow-lg")}>
        <Container>
          <Row>
            <Col md={{ offset: 2, span: 7 }}>
              <Fade bottom cascade>
                <h2 className="mb-0">MATEMÁTICAS</h2>
                <h2>SIMPLIFICADAS</h2>
                <div className="mt-3 mt-lg-0">
                  <p>
                    ● Plataforma educativa enfocada en el aprendizaje de las
                    matemáticas.
                  </p>
                  <p>
                    ● Miles de ejercicios con explicaciones y videos tutoriales.
                  </p>
                  <p>
                    ● Aprende matemáticas a tu propio ritmo y con un método
                    único.
                  </p>
                </div>
              </Fade>
              <Button
                className="mt-4 genericButton"
                hasShadow={true}
                onClick={() => (window.location.href = "/signup")}
                size="lg"
              >
                Regístrate
              </Button>
            </Col>
          </Row>
        </Container>
      </Jumbotron>
      <div className={styles.wrapper}>
        <section className={styles.whoAreWe}>
          <Container>
            <Row>
              <Col lg>
                <Fade>
                  <h2>¿Quiénes somos?</h2>
                  <p className="text-justify">
                    MeXmáticas nace en el 2018 bajo la necesidad de impulsar el
                    potencial matemático de los alumnos en México. Somos la
                    única plataforma matemática mexicana que fomenta el
                    aprendizaje autodidacta sin descuidar la importancia del
                    maestro dentro del aula.
                  </p>
                  <h2>Objetivo</h2>
                  <p className="text-justify">
                    Nuestro objetivo es fomentar la importancia de las
                    matemáticas en los estudiantes y cambiar el paradigma social
                    que se tiene sobre esta ciencia, formando una nueva
                    generación de estudiantes preparados para su vida académica
                    y laboral.
                  </p>
                </Fade>
              </Col>
              <Col className="d-flex align-items-center" lg>
                <Image
                  className="mt-lg-0 shadow"
                  fluid
                  rounded
                  src="/images/landing/whoAreWe.jpg"
                />
              </Col>
            </Row>
          </Container>
        </section>
        <hr className={mexmaticasDivider} />
        <section className={styles.ourCourses}>
          <Container>
            <Row>
              <Col>
                <Fade>
                  <>
                    <h1>Nuestros Cursos</h1>
                    {!courses && (
                      <div
                        className="text-center"
                        style={{ margin: "150px 0px" }}
                      >
                        <Spinner animation="border" role="status" />
                      </div>
                    )}
                    {courses?.length ? (
                      <Tab.Container defaultActiveKey={`#${courses[0].school}`}>
                        <Row>
                          <Col md={5}>
                            <ListGroup className="mb-3 mb-md-0">
                              {courses.map((c, idx) => (
                                <ListGroup.Item
                                  action
                                  className={cn(
                                    "shadow-sm rounded",
                                    isEqual(courseSelected, idx)
                                      ? styles.activeCourseMenuItem
                                      : styles.courseMenuItem
                                  )}
                                  href={`#${c.school}`}
                                  key={idx}
                                  onClick={() => setCourseSelected(idx)}
                                >
                                  <h4
                                    className="mb-0"
                                    style={{ fontWeight: 900 }}
                                  >
                                    <i
                                      className="fas fa-graduation-cap mr-3"
                                      style={{ color: "#49bf84" }}
                                    />
                                    {c.school}
                                  </h4>
                                </ListGroup.Item>
                              ))}
                            </ListGroup>
                          </Col>
                          <Col md={7}>
                            <Tab.Content>
                              {courses.map((c, idx) => (
                                <Tab.Pane key={idx} eventKey={`#${c.school}`}>
                                  <HomeCard
                                    courses={c.courses.map((cc) => ({
                                      title: cc.name,
                                      list: [...cc.topicsSummary],
                                    }))}
                                  />
                                </Tab.Pane>
                              ))}
                            </Tab.Content>
                          </Col>
                        </Row>
                      </Tab.Container>
                    ) : null}
                  </>
                </Fade>
              </Col>
            </Row>
          </Container>
        </section>
        <hr className={mexmaticasDivider} />
        <section className={styles.misionAndVision}>
          <Container>
            <Row>
              <Col className="d-flex align-items-center" lg>
                <Image
                  className="mt-lg-0 shadow"
                  fluid
                  rounded
                  src="/images/landing/misionAndVision.jpg"
                />
              </Col>
              <Col lg>
                <Fade>
                  <h2>Misión</h2>
                  <p className="text-justify">
                    Desarrollar las habilidades matemáticas de cada estudiante a
                    través de una plataforma educativa virtual.
                  </p>
                  <h2>Visión</h2>
                  <p className="text-justify">
                    Proporcionar a los estudiantes del mundo, una plataforma
                    educativa virtual que les facilite el aprendizaje de las
                    matemáticas, el cuál les permitirá tener un alto nivel de
                    competitividad.
                  </p>
                </Fade>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </StudentLayout>
  );
};
