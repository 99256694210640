import React, { useContext } from "react";
import { number } from "prop-types";
import Flash from "react-reveal/Flash";
import { LanguageContext } from "../../../languages";

export const FreestyleQPoints = React.memo(({ score }) => {
  const { displayText } = useContext(LanguageContext);

  return (
    <div
      title={displayText("exampPage.questionsContainer.freestylePoints")}
      className="d-inline ml-3"
      style={{ color: "#0f5257" }}
    >
      <Flash>
        <i className="fas fa-trophy" />
        <strong className="ml-1">{score}</strong>
        <strong className="ml-1">pts.</strong>
      </Flash>
    </div>
  );
});

FreestyleQPoints.propTypes = {
  score: number.isRequired,
};

FreestyleQPoints.displayName = "FreestyleQPoints";
