import React, { useState, useEffect, useContext } from "react";
import { ListGroup, Spinner } from "react-bootstrap";
import {
  ConversationModal,
  ListGroupItem,
  StudentLayout,
  StudentTitle,
} from "../../components";
import { fetchConversations, setConversationSeen } from "../../services";
import { useSelector } from "react-redux";
import { errorLogger } from "../../errors/errorLogger";
import { isEmpty } from "lodash";
import { useConversations } from "../../hooks/useConversations";
import { EmptyBox } from "../components/EmptyBox";
import { LanguageContext } from "../../languages";

export const MessagesPage = () => {
  const [conversations, setConversations] = useState();
  const [activeConversation, setActiveConversation] = useState(null);
  const [messagesWereRemoved, setMessagesWereRemoved] = useState(false);

  const student = useSelector((state) => state.student);

  const { displayText } = useContext(LanguageContext);

  const { formattedConversations } = useConversations(
    conversations,
    student?._id
  );

  useEffect(() => {
    if (!student?._id) return;
    fetchConversations("student", student?._id)
      .then((res) => setConversations(res.data))
      .catch((err) => errorLogger(err));
  }, [student]);

  const handleClose = () => {
    if (messagesWereRemoved) {
      window.location.reload();
      return;
    }
    setActiveConversation(null);
  };

  const handleShow = async (conversation) => {
    try {
      const _messagesWereRemoved = await setConversationSeen(
        "student",
        student?._id,
        conversation._id
      ).then((res) => res.data);
      setMessagesWereRemoved(_messagesWereRemoved);
      setActiveConversation(conversation);
    } catch (err) {
      errorLogger(err);
    }
  };

  const StudentConversation = ({ item }) => (
    <ListGroupItem
      content={<div className="d-flex flex-column">{item.body}</div>}
      handleOnClick={() => handleShow(item)}
      hasRedDot={item.hasPendingMessages}
      title={item.title}
    />
  );

  return (
    <StudentLayout>
      <div className="pb-4">
        <StudentTitle text={displayText("messages.title")} />
        {!conversations && (
          <div className="text-center mt-4 pt-4">
            <Spinner animation="border" variant="success" />
          </div>
        )}
        {!(conversations || []).length && (
          <div className="text-center">
            <EmptyBox text={displayText("messages.empty")} />
          </div>
        )}
        {!isEmpty(formattedConversations) && (
          <ListGroup>
            {formattedConversations.map((conv) => (
              <StudentConversation item={conv} key={conv._id} />
            ))}
          </ListGroup>
        )}
        {/* show conversation modal if there's an active message */}
        <ConversationModal
          {...{
            activeConversation,
            handleClose,
            myId: student?._id,
            receiver: activeConversation?.receiver,
            sender: activeConversation?.sender,
            show: !!activeConversation,
          }}
        />
      </div>
    </StudentLayout>
  );
};
