import React, { useContext, useEffect } from "react";
import { array } from "prop-types";
import { Alert, Row, Col } from "react-bootstrap";
import WrongAnswer from "./WrongAnswer";
import { ImageFromFirebase } from "../../../components";
import { LanguageContext } from "../../../languages";
import { displayTrueOrFalse } from "../../../utils/helpers";

export const MyResults = React.memo(({ results }) => {
  const { displayText, language } = useContext(LanguageContext);

  // needed to render latex
  useEffect(() => {
    if (!!window?.MathJax) {
      window.MathJax.typesetClear();
      window.MathJax.typeset();
    }
  }, []);

  return (
    <div className="mt-4">
      <h4 className="mb-3">
        {displayText("resultsPage.myResults.yourAnswers")}
      </h4>
      {results.map((q) => {
        return q.qCorrectAnswers.answer === q.userAnswers.answer ? (
          <Alert className="shadow-sm" key={q._id} variant="success">
            <Row>
              <Col>
                <strong>
                  <i className="fas fa-check mr-1" />
                  {displayText("resultsPage.myResults.correct")}
                </strong>
                {/* TECHNICAL INSTRUCTION */}
                <div className="d-flex flex-column my-2">
                  <span className="text-break">{q.qInstruction}</span>
                  {q.qTechnicalInstruction ? (
                    q.qTechnicalInstruction.type === "text" ? (
                      <span>{q.qTechnicalInstruction.text}</span>
                    ) : (
                      <ImageFromFirebase
                        className="mb-2"
                        isFluid={true}
                        path={q.qTechnicalInstruction.imageLink}
                        rounded
                      />
                    )
                  ) : null}
                </div>
                {/* USER ANSWERS */}
                <div className="d-flex flex-column">
                  <strong className="mb-2">
                    {displayText("resultsPage.myResults.yourAnswer")}
                  </strong>
                  {q.userAnswers.type === "text" ? (
                    <span>
                      {displayTrueOrFalse(q.userAnswers.answer, language)}
                    </span>
                  ) : (
                    <ImageFromFirebase
                      className="my-2 mb-3"
                      isFluid={true}
                      path={q.userAnswers.answerImageUrl}
                      rounded
                    />
                  )}
                </div>
              </Col>
            </Row>
          </Alert>
        ) : (
          <WrongAnswer
            key={q._id}
            qInstruction={q.qInstruction}
            qTechnicalInstruction={q.qTechnicalInstruction}
            userAnswers={q.userAnswers}
            qCorrectAnswers={q.qCorrectAnswers}
          />
        );
      })}
    </div>
  );
});

MyResults.propTypes = {
  results: array.isRequired,
};

MyResults.displayName = "MyResults";
