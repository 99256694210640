import React, { useEffect, useState } from "react";
import { Form, Col } from "react-bootstrap";
import { string } from "prop-types";
import { Formik, ErrorMessage, Field } from "formik";
import * as yup from "yup";
import { assignTeacher, fetchTeachers } from "../../../../services";
import {
  AdminSpinner,
  Button,
  DropdownWithSearch,
} from "../../../../components";
import { getFullName } from "../../../../utils/helpers";

export const ClassroomTeachersForm = React.memo(({ formInitialText }) => {
  const [availableTeachers, setAvailableTeachers] = useState();

  const url = new URL(window.location.href);
  const classroomId = url.href.split("/").pop();

  const yupschema = yup.object({
    newTeacherId: yup.string(),
  });

  useEffect(() => {
    fetchTeachers()
      .then(({ data: _teachers }) =>
        setAvailableTeachers(
          _teachers
            .map((t) => ({
              ...t,
              fullName: getFullName(t?.name, t?.firstSurname, t?.secondSurname),
            }))
            .sort((a, b) => (a.fullName < b.fullName ? -1 : 1))
        )
      )
      .catch((err) => {
        alert("Ocurrió un error.");
        console.log(err);
      });
  }, []);

  return (
    <Formik
      initialValues={{
        currentTeacherId: formInitialText,
        newTeacherId: formInitialText,
      }}
      validationSchema={yupschema}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        try {
          const { currentTeacherId, newTeacherId } = values;
          await assignTeacher({
            classroomId,
            currentTeacherId,
            newTeacherId,
          });

          window.location.reload();
        } catch (err) {
          console.log(err);
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          {/* teachers */}
          {availableTeachers ? (
            <Form.Row>
              <Form.Group as={Col}>
                <Field name="newTeacherId" id="newTeacherId" type="string">
                  {({ field: { value }, form: { setFieldValue } }) => (
                    <DropdownWithSearch
                      {...{
                        data: availableTeachers,
                        handleSelectValue: (selected) =>
                          setFieldValue("newTeacherId", selected?.value),
                        hasBlank: true,
                        label: "Editar maestro",
                        labelAccessor: "fullName",
                        selectedValue: value,
                        toggleText: "Elige...",
                        valueAccessor: "_id",
                      }}
                    />
                  )}
                </Field>
                <ErrorMessage
                  className="text-danger"
                  component="div"
                  name="newTeacherId"
                />
              </Form.Group>
            </Form.Row>
          ) : (
            <AdminSpinner />
          )}
          {/* buttons */}
          <Form.Group>
            <Button
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
              isSubmit
            />
          </Form.Group>
        </Form>
      )}
    </Formik>
  );
});

ClassroomTeachersForm.propTypes = {
  formLabel: string,
  formInitialText: string,
};

ClassroomTeachersForm.display = "ClassroomTeachersForm";
