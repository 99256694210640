import React, { memo } from "react";
import { Button } from "./Button";
import { bool, func, oneOf, string } from "prop-types";
import { isEqual } from "lodash";
import cn from "classnames";
import { getSvg } from "../../utils/helpers";

import styles from "./iconbutton.module.scss";

export const IconButton = memo(
  ({ className, hoverText, href, isDisabled = false, onClick, svg }) => {
    const getClassName = () => {
      if (isEqual(svg, "delete")) return styles.deleteButton;
      return styles.button;
    };

    return (
      <Button
        {...{
          className: cn(getClassName(), className),
          hasShadow: false,
          isDisabled,
          ...(hoverText && { hoverText }),
          ...(href && { onClick: () => (window.location.href = href) }),
          ...(onClick && { onClick }),
        }}
      >
        {getSvg(svg)}
      </Button>
    );
  }
);

IconButton.propTypes = {
  className: string,
  hoverText: string,
  href: string,
  isDisabled: bool,
  onClick: func,
  svg: oneOf(["add", "anchor", "chart", "copy", "delete", "edit", "eye", "popup"]),
};

IconButton.displayName = "IconButton";
