import React from "react";
import { string } from "prop-types";

export const QInstruction = React.memo(({ qInstruction }) => {
  return (
    <h4 className="mb-2 mt-2">
      <strong className="text-break">{qInstruction}</strong>
    </h4>
  );
});

QInstruction.propTypes = {
  qInstruction: string.isRequired,
};

QInstruction.displayName = "QInstruction";
