import React, { memo } from "react";
import { any, func, string } from "prop-types";
import { Card as BootstrapCard, Image } from "react-bootstrap";
import cn from "classnames";

import styles from "./card.module.scss";

export const Card = memo(
  ({ children, footer, hoverText, imageName, onClick, title }) => {
    const className = onClick ? styles.clickableCard : styles.card;

    return (
      <BootstrapCard
        {...{
          className,
          ...(hoverText && { title: hoverText }),
          ...(onClick && { onClick }),
        }}
      >
        <BootstrapCard.Body>
          {imageName && (
            <Image
              className="mb-2"
              height="55"
              src={`/images/${imageName}`}
              width="55"
            />
          )}
          {title && (
            <strong className={cn("lead d-block", styles.title)}>
              {title}
            </strong>
          )}
          <div>{children}</div>
        </BootstrapCard.Body>
        {footer && (
          <BootstrapCard.Footer className="text-muted">
            {footer}
          </BootstrapCard.Footer>
        )}
      </BootstrapCard>
    );
  }
);

Card.propTypes = {
  children: any.isRequired,
  footer: string,
  hoverText: string,
  imageName: string,
  onClick: func,
  title: string,
};

Card.displayName = "Card";
