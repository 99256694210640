import React, { memo } from "react";
import { OverlayTrigger, Tooltip as BootstrapTooltip } from "react-bootstrap";
import { func, object, oneOf, oneOfType, string } from "prop-types";
import { getSvg } from "../../utils/helpers";
import { isEqual } from "lodash";
import cn from "classnames";

import styles from "./tooltip.module.scss";

export const Tooltip = memo(
  ({ className, svg = "question", text, variant = "gray" }) => {
    const getClassName = () => {
      if (isEqual(variant, "main")) return styles.mainTooltip;
      return styles.grayTooltip;
    };

    return (
      <OverlayTrigger
        placement="right"
        overlay={<BootstrapTooltip>{text}</BootstrapTooltip>}
        className="ml-2"
      >
        {getSvg(svg, cn(getClassName(), className))}
      </OverlayTrigger>
    );
  }
);

Tooltip.propTypes = {
  className: string,
  svg: string,
  text: oneOfType([func, object, string]).isRequired,
  variant: oneOf(["gray", "main"]),
};

Tooltip.displayName = "Tooltip";
