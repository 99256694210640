const alertError = (code) => {
  switch (code) {
    case "auth/invalid-email":
      alert("El correo es inválido.");
      break;
    case "auth/user-disabled":
      alert("El usuario está deshabilitado.");
      break;
    case "auth/user-not-found":
      alert("El correo no se ha encontrado.");
      break;
    case "auth/wrong-password":
      alert("La contraseña es incorrecta.");
      break;
    default:
      console.log("Error code not supported.");
  }
};

export const errorLogger = (error, callback) => {
  if (error.code) alertError(error.code);
  if (error.message || error)
    console.log("ERROR MESSAGE:", error.message || error);
  console.error(error);

  callback && callback();
};
