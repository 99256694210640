import React, { useContext } from "react";
import { array, func, string } from "prop-types";
import { Button } from "react-bootstrap";
import { LanguageContext } from "../../../languages";
import { displayDichotomousQuestion } from "../../../utils/helpers";
import { ImageFromFirebase } from "../../images/ImageFromFirebase";

export const QMultipleChoice = React.memo(
  ({
    type,
    textChoices,
    imageChoices,
    getValueFromMultipleChoice,
    choiceSelected,
  }) => {
    const { language } = useContext(LanguageContext);

    return type === "text" ? (
      <div className="mt-3">
        {textChoices.map((c) => (
          <Button
            key={c}
            variant="outline-secondary"
            className="mr-2 mb-2 mb-md-0"
            active={choiceSelected === c}
            onClick={() => getValueFromMultipleChoice(c)}
          >
            {displayDichotomousQuestion(type, textChoices, c, language)}
          </Button>
        ))}
      </div>
    ) : type === "image" ? (
      <div className="mt-3">
        {imageChoices.map((c, idx) => (
          <Button
            active={choiceSelected === c}
            className="m-2 mb-2 mb-md-0"
            key={c}
            onClick={() => getValueFromMultipleChoice(String(idx + 1))}
            variant="outline-secondary"
          >
            <ImageFromFirebase height="200" path={c} rounded width="250" />
          </Button>
        ))}
      </div>
    ) : null;
  }
);

QMultipleChoice.propTypes = {
  type: string.isRequired,
  textChoices: array,
  imageChoices: array,
  getValueFromMultipleChoice: func.isRequired,
  choiceSelected: string,
};

QMultipleChoice.displayName = "QMultipleChoice";
