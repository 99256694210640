import React, { useContext } from "react";
import { string } from "prop-types";
import { LanguageContext } from "../../../languages";

export const DifficultyStars = React.memo(({ difficulty }) => {
  const { displayText } = useContext(LanguageContext);

  const calculateDifficulty = () => {
    switch (difficulty) {
      case "Basic":
        return (
          <>
            <i className="fas fa-star shadow" />
            <i className="far fa-star ml-1 shadow" />
            <i className="far fa-star ml-1 shadow" />
            <i className="far fa-star ml-1 shadow" />
            <i className="far fa-star ml-1 shadow" />
            <br />
            <span className="text-info">
              <strong>{displayText("coursePage.levels.basic")}</strong>
            </span>
          </>
        );
      case "Basic-Intermediate":
        return (
          <>
            <i className="fas fa-star shadow" />
            <i className="fas fa-star ml-1 shadow" />
            <i className="far fa-star ml-1 shadow" />
            <i className="far fa-star ml-1 shadow" />
            <i className="far fa-star ml-1 shadow" />
            <br />
            <span className="text-info">
              <strong>
                {displayText("coursePage.levels.basicIntermediate")}
              </strong>
            </span>
          </>
        );
      case "Intermediate":
        return (
          <>
            <i className="fas fa-star shadow" />
            <i className="fas fa-star ml-1 shadow" />
            <i className="fas fa-star ml-1 shadow" />
            <i className="far fa-star ml-1 shadow" />
            <i className="far fa-star ml-1 shadow" />
            <br />
            <span className="text-info">
              <strong>{displayText("coursePage.levels.intermediate")}</strong>
            </span>
          </>
        );
      case "Intermediate-Advanced":
        return (
          <>
            <i className="fas fa-star shadow" />
            <i className="fas fa-star ml-1 shadow" />
            <i className="fas fa-star ml-1 shadow" />
            <i className="fas fa-star ml-1 shadow" />
            <i className="far fa-star ml-1 shadow" />
            <br />
            <span className="text-info">
              <strong>
                {displayText("coursePage.levels.intermediateAdvanced")}
              </strong>
            </span>
          </>
        );
      case "Advanced":
        return (
          <>
            <i className="fas fa-star shadow" />
            <i className="fas fa-star ml-1 shadow" />
            <i className="fas fa-star ml-1 shadow" />
            <i className="fas fa-star ml-1 shadow" />
            <i className="fas fa-star ml-1 shadow" />
            <br />
            <span className="text-info">
              <strong>{displayText("coursePage.levels.advanced")}</strong>
            </span>
          </>
        );
      default:
        break;
    }
  };

  return (
    <p
      className="text-info mb-2"
      title={displayText("coursePage.levels.difficulty")}
    >
      {calculateDifficulty()}
    </p>
  );
});

DifficultyStars.propTypes = {
  difficulty: string.isRequired,
};

DifficultyStars.displayName = "DifficultyStars";
