import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { AdminSpinner, TeacherLayout } from "../../../components";
import { errorLogger } from "../../../errors/errorLogger";
import { fetchClassroomCatalogue } from "../../../services";
import { ClassroomTabs } from "../components/ClassroomTabs";

export const TeacherClassroomExams = (props) => {
  const [classroomName, setClassroomName] = useState();
  const [catalogue, setCatalogue] = useState();

  const classroomId = props.routeProps.match.params.classroomId;

  useEffect(() => {
    if (!classroomId) return;
    try {
      fetchClassroomCatalogue(classroomId).then((res) => {
        setClassroomName(res.data.classroomName);
        setCatalogue(res.data.classroomCourses);
      });
    } catch (err) {
      errorLogger(err);
    }
  }, [classroomId]);

  return (
    <TeacherLayout
      backBttn={`/teacher/classrooms/${classroomId}`}
      expanded
      hasScrollToTopButton={true}
      leftBarActive="Salones"
      topNavTitle={`Desempeño: ${classroomName}`}
    >
      {!catalogue && <AdminSpinner />}
      {catalogue && isEmpty(catalogue) && <p>Salón sin cursos.</p>}
      {catalogue && !isEmpty(catalogue) && <ClassroomTabs {...{ catalogue }} />}
    </TeacherLayout>
  );
};
