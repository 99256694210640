import React, { memo } from "react";
import { string } from "prop-types";
import { Image } from "react-bootstrap";

import styles from "./emptybox.module.scss";

export const EmptyBox = memo(({ text }) => (
  <>
    <Image src="/images/emptybox.png" className={styles.emptyBox} />
    <em className="d-block lead" style={{ color: "#b3b3b3" }}>
      {text}
    </em>
  </>
));

EmptyBox.propTypes = {
  text: string,
};

EmptyBox.DisplayName = "EmptyBox";
