import React, { useContext, useMemo } from "react";
import Switch from "react-switch";
import Image from "react-bootstrap/Image";
import { isEqual } from "lodash";
import { LanguageContext } from "../../languages";

export const LanguageSwitch = ({ className }) => {
  const { language, setLanguage } = useContext(LanguageContext);

  const isMexicoChecked = useMemo(() => isEqual(language, "SPA"), [language]);

  const handleChange = () => setLanguage(isMexicoChecked ? "ENG" : "SPA");

  return (
    <div {...{ className }}>
      <Switch
        checked={isMexicoChecked}
        checkedIcon={
          <Image
            alt="México"
            className="p-1"
            fluid
            src="/images/flags/mexico.png"
          />
        }
        className="react-switch"
        height={28}
        offColor="#586071"
        onChange={handleChange}
        onColor="#586071"
        uncheckedIcon={
          <Image
            alt="United States"
            className="p-1"
            fluid
            src="/images/flags/united-states.png"
          />
        }
        width={56}
      />
    </div>
  );
};
