import React, { useEffect, useState } from "react";
import { Form, Col } from "react-bootstrap";
import { string } from "prop-types";
import { Formik, ErrorMessage, Field } from "formik";
import * as yup from "yup";
import {
  fetchInstitutions,
  updateClassroomInstitution,
} from "../../../../services";
import { Button, DropdownWithSearch } from "../../../../components";

export const ClassroomInstitutionForm = React.memo(({ formInitialText }) => {
  const [institutions, setInstitutions] = useState();

  const url = new URL(window.location.href);
  const classroomId = url.href.split("/").pop();

  const yupschema = yup.object({
    newInstitution: yup.string(),
  });

  useEffect(() => {
    fetchInstitutions()
      .then(({ data: _institutions }) =>
        setInstitutions(
          _institutions
            .map(({ _id, name }) => ({
              _id,
              name,
            }))
            .sort((a, b) =>
              String(a.name).toUpperCase().trim() >
              String(b.name).toUpperCase().trim()
                ? 1
                : -1
            )
        )
      )
      .catch((err) => {
        console.log(err);
        alert("Ocurrió un error, vuelve a intentarlo.");
      });
  }, []);

  return (
    <Formik
      initialValues={{
        newInstitution: formInitialText,
      }}
      validationSchema={yupschema}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        values.classroomId = classroomId;
        updateClassroomInstitution(values)
          .then(() => window.location.reload())
          .catch((err) => {
            alert("Ocurrió un error.");
            setSubmitting(false);
            console.log(err);
          });
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          {/* name */}
          <Form.Row>
            <Form.Group as={Col}>
              <Field name="newInstitution" id="newInstitution" type="string">
                {({ field: { value }, form: { setFieldValue } }) => (
                  <DropdownWithSearch
                    {...{
                      data: institutions,
                      handleSelectValue: (selected) =>
                        setFieldValue("newInstitution", selected?.value),
                      hasBlank: true,
                      label: "Editar escuela",
                      labelAccessor: "name",
                      selectedValue: value,
                      toggleText: "Elige...",
                      valueAccessor: "_id",
                    }}
                  />
                )}
              </Field>
              <ErrorMessage
                className="text-danger"
                name="newInstitution"
                component="div"
              />
            </Form.Group>
          </Form.Row>
          {/* buttons */}
          <Form.Group>
            <Button
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
              isSubmit
            />
          </Form.Group>
        </Form>
      )}
    </Formik>
  );
});

ClassroomInstitutionForm.propTypes = {
  formLabel: string,
  formInitialText: string,
};

ClassroomInstitutionForm.display = "ClassroomInstitutionForm";
