export const APP_VERSION = "10.13";

export const IMAGES = {
  SIZE: 1000000,
  SUPPORTED_FORMATS: ["image/jpg", "image/jpeg", "image/gif", "image/png"],
};

export const PDFS = {
  SIZE: 4000000,
  SUPPORTED_FORMATS: ["application/pdf"],
};

export const USERS = {
  ADMIN: "admin@mexmaticas.com",
  GUEST: "Guest",
  STUDENT: "Student",
  TEACHER: "Teacher",
};

export const TEACHER_PAGES = {
  STUDENTS: {
    PAGE_SIZE: 50,
    SORT_OPTIONS: [
      "Más Recientes",
      "Más Antiguos",
      "Por Nombre Asc",
      "Por Nombre Desc",
      "Por Email Asc",
      "Por Email Desc",
    ],
  },
  EXAMS: {
    PAGE_SIZE: 50,
    SORT_OPTIONS: ["Más Recientes", "Más Antiguos"],
  },
};

export const ADMIN_PAGES = {
  COURSES: {
    PAGE_SIZE: 50,
    SORT_OPTIONS: [
      "Más Recientes",
      "Más Antiguos",
      "Por Nombre Asc",
      "Por Nombre Desc",
    ],
    FILTER_BUTTONS: ["Primaria", "Secundaria", "Preparatoria", "Universidad"],
  },
  CLASSROOMS: {
    PAGE_SIZE: 50,
    SORT_OPTIONS: [
      "Más Recientes",
      "Más Antiguos",
      "Por Nombre Asc",
      "Por Nombre Desc",
    ],
    FILTER_BUTTONS: ["Primaria", "Secundaria", "Preparatoria", "Universidad"],
  },
  INSTITUTIONS: {
    PAGE_SIZE: 50,
    SORT_OPTIONS: [
      "Más Recientes",
      "Más Antiguos",
      "Por Nombre Asc",
      "Por Nombre Desc",
    ],
  },
  MESSAGES: {
    PAGE_SIZE: 50,
  },
  STUDENTS: {
    PAGE_SIZE: 50,
    SORT_OPTIONS: [
      "Más Recientes",
      "Más Antiguos",
      "Por Nombre Asc",
      "Por Nombre Desc",
      "Por Email Asc",
      "Por Email Desc",
    ],
    HISTORY: {
      PAGE_SIZE: 50,
      SORT_OPTIONS: ["Más Recientes", "Más Antiguos"],
    },
  },
  TEACHERS: {
    PAGE_SIZE: 50,
    SORT_OPTIONS: [
      "Más Recientes",
      "Más Antiguos",
      "Por Nombre Asc",
      "Por Nombre Desc",
      "Por Email Asc",
      "Por Email Desc",
    ],
    MESSAGES: { PAGE_SIZE: 50 },
  },
};

export const EXAM_VALUES = {
  GRADE_TO_APPROVE: 8,
  PERFECT_GRADE: 10,
};
