import React, { memo } from "react";
import { AdminPagination, AdminSpinner, DraggableList } from "../";
import { ListGroup, Table as BootstrapTable } from "react-bootstrap";
import { array, arrayOf, bool, func, number, string } from "prop-types";

export const AdminDataTemplate = memo(
  ({
    activePage,
    data,
    emptyMessage,
    handleChangePage,
    handleOnChangeOrder,
    isTable = false,
    limit,
    mapItemFunc,
    offset,
    pages,
    pageSize,
    tableHeaders = [],
  }) => {
    const Pagination = () =>
      data.length > pageSize && (
        <div className="mt-3">
          <AdminPagination
            activePage={activePage}
            handleChangePage={(p) => handleChangePage(p)}
            pageCount={pages}
          />
        </div>
      );

    const Table = ({ headers, rows }) => (
      <BootstrapTable striped bordered hover size="sm">
        <thead>
          <tr>
            {headers.map((header, idx) => (
              <th key={idx}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>{rows.slice(offset, limit).map(mapItemFunc)}</tbody>
      </BootstrapTable>
    );

    return data ? (
      data.length ? (
        <>
          {!isTable && !handleOnChangeOrder && (
            <ListGroup>{data.slice(offset, limit).map(mapItemFunc)}</ListGroup>
          )}
          {!isTable && handleOnChangeOrder && (
            <ListGroup>
              <DraggableList
                customMap={(state) => state.map(mapItemFunc)}
                handleOnChange={handleOnChangeOrder}
                list={{ data: data.slice(offset, limit) }}
              />
            </ListGroup>
          )}
          {isTable && <Table headers={tableHeaders} rows={data} />}
          <Pagination />
        </>
      ) : (
        <div className="text-center mt-4">{emptyMessage}</div>
      )
    ) : (
      <AdminSpinner />
    );
  }
);

AdminDataTemplate.propTypes = {
  activePage: number.isRequired,
  data: array,
  emptyMessage: string.isRequired,
  handleChangePage: func.isRequired,
  handleOnChangeOrder: func,
  isTable: bool,
  limit: number.isRequired,
  mapItemFunc: func.isRequired,
  offset: number.isRequired,
  pages: number,
  pageSize: number.isRequired,
  tableHeaders: arrayOf(string),
};

AdminDataTemplate.displayName = "AdminDataTemplate";
