import React from "react";
import { Form, Col } from "react-bootstrap";
import { Formik, ErrorMessage } from "formik";
import * as yup from "yup";
import { newMultipleOptionWithImages } from "../../../../services";
import { useSelector } from "react-redux";
import { firebaseStorage } from "../../../../firebase/firebase";
import { IMAGES } from "../../../../utils/constants";
import { object } from "prop-types";
import { Button, ImageFromFirebase } from "../../../../components";

export const MultipleOptionWithImages = ({ question }) => {
  const yupschema = yup.object({
    qComment: yup.string(),
    qCorrectAnswers: yup
      .string()
      .test(
        "custom-validation",
        "La respuesta debe ser un número del 1 al 4",
        (value) => {
          return ["1", "2", "3", "4"].includes(value);
        }
      ),
    qInstruction: yup.string().required("Requerido"),
    ...(!!question
      ? {}
      : {
          file: yup
            .mixed()
            .required("Requerido")
            .test(
              "fileFormat",
              "Formato no soportado",
              (value) => value && IMAGES.SUPPORTED_FORMATS.includes(value.type)
            ),
          fileOption1: yup
            .mixed()
            .required("Requerido")
            .test(
              "fileFormat",
              "Formato no soportado",
              (value) => value && IMAGES.SUPPORTED_FORMATS.includes(value.type)
            ),
          fileOption2: yup
            .mixed()
            .required("Requerido")
            .test(
              "fileFormat",
              "Formato no soportado",
              (value) => value && IMAGES.SUPPORTED_FORMATS.includes(value.type)
            ),
          fileOption3: yup
            .mixed()
            .required("Requerido")
            .test(
              "fileFormat",
              "Formato no soportado",
              (value) => value && IMAGES.SUPPORTED_FORMATS.includes(value.type)
            ),
          fileOption4: yup
            .mixed()
            .required("Requerido")
            .test(
              "fileFormat",
              "Formato no soportado",
              (value) => value && IMAGES.SUPPORTED_FORMATS.includes(value.type)
            ),
        }),
  });

  const courseId = useSelector((state) => state.admin.course.courseId);
  const topicId = useSelector((state) => state.admin.topic.topicId);
  const examId = useSelector((state) => state.admin.exam.examId);

  const mainQuestionImageUrl = question?.qTechnicalInstruction?.imageLink;
  const option1ImageUrl = question?.qMultipleChoice?.imageChoices?.[0];
  const option2ImageUrl = question?.qMultipleChoice?.imageChoices?.[1];
  const option3ImageUrl = question?.qMultipleChoice?.imageChoices?.[2];
  const option4ImageUrl = question?.qMultipleChoice?.imageChoices?.[3];

  return (
    <Formik
      initialValues={{
        file: undefined,
        image: undefined,
        qComment: question?.qComment || "",
        qCorrectAnswers: question?.qCorrectAnswers[0]?.answer || "",
        qInstruction: question?.qInstruction || "",
        fileOption1: undefined,
        imageOption1: undefined,
        fileOption2: undefined,
        imageOption2: undefined,
        fileOption3: undefined,
        imageOption3: undefined,
        fileOption4: undefined,
        imageOption4: undefined,
      }}
      validationSchema={yupschema}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);

        values.qInstruction = values.qInstruction.trim();
        values.qComment = values.qComment.trim();
        values.courseId = courseId;
        values.topicId = topicId;
        values.examId = examId;
        values.currentQuestionId = question?._id;

        const images = [
          { imgFile: values.file, imgName: "imagen" },
          { imgFile: values.fileOption1, imgName: "imageOption1" },
          { imgFile: values.fileOption2, imgName: "imageOption2" },
          { imgFile: values.fileOption3, imgName: "imageOption3" },
          { imgFile: values.fileOption4, imgName: "imageOption4" },
        ];

        try {
          // post question to mongodb
          const questionId = await newMultipleOptionWithImages(values).then(
            (res) => res.data
          );

          // upload only images that have changed
          for (const image of images) {
            const { imgFile, imgName } = image;
            const hasImageChanged = !!imgFile;
            if (!hasImageChanged) continue;
            const storageRef = firebaseStorage.ref();
            const pathOnFirebaseStorage = `${courseId}/${topicId}/exams/${examId}/${questionId}/${imgName}`;
            const fileRef = storageRef.child(pathOnFirebaseStorage);
            await fileRef.put(imgFile);
          }

          window.location.reload();
        } catch (err) {
          console.log(err);
          setSubmitting(false);
          alert("Ocurrió un error en el servidor, intenta más tarde");
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          {/* qInstruction */}
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>
                Instrucción
                <strong className="text-danger" title="Requerido">
                  *
                </strong>
              </Form.Label>
              <Form.Control
                maxLength="500"
                type="text"
                as="textarea"
                rows="1"
                name="qInstruction"
                value={values.qInstruction}
                onChange={handleChange}
                onBlur={handleBlur}
                isValid={touched.qInstruction && !errors.qInstruction}
                isInvalid={touched.qInstruction && !!errors.qInstruction}
              />
              <ErrorMessage
                className="text-danger"
                name="qInstruction"
                component="div"
              />
            </Form.Group>
          </Form.Row>
          {/* qTechnicalInstruction (image) */}
          <Form.Row className="mb-3">
            <Form.Label>
              Imagen
              {!question || (values.image && values.file) ? (
                <strong className="text-danger" title="Requerido">
                  *
                </strong>
              ) : (
                <small> (Opcional)</small>
              )}
              <small className="ml-1">(.jpg, .jpeg, .gif y .png)</small>
              {mainQuestionImageUrl && !values.image && !values.file && (
                <>
                  <br />
                  <ImageFromFirebase
                    className="mt-2"
                    height="85"
                    path={mainQuestionImageUrl}
                    width="85"
                  />
                </>
              )}
            </Form.Label>
            <Form.File
              encType="multipart/form-data"
              accept="image/*"
              label={values.image ? values.image : ""}
              data-browse="Buscar"
              id="file"
              name="file"
              type="file"
              onChange={(event) => {
                setFieldValue("file", event.currentTarget.files[0]);
                setFieldValue(
                  "image",
                  event.currentTarget.files[0]
                    ? event.currentTarget.files[0].name
                    : ""
                );
              }}
              onBlur={handleBlur}
              custom
            />
            <ErrorMessage className="text-danger" name="file" component="div" />
          </Form.Row>
          {/* image option 1 */}
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>
                Opción 1
                <strong className="text-danger" title="Requerido">
                  *
                </strong>
                <small className="ml-1">(.jpg, .jpeg, .gif y .png)</small>
                {option1ImageUrl &&
                  !values.imageOption1 &&
                  !values.fileOption1 && (
                    <>
                      <br />
                      <ImageFromFirebase
                        className="mt-2"
                        height="85"
                        path={option1ImageUrl}
                        width="85"
                      />
                    </>
                  )}
              </Form.Label>
              <Form.File
                encType="multipart/form-data"
                accept="image/*"
                label={values.imageOption1 ? values.imageOption1 : ""}
                data-browse="Buscar"
                id="fileOption1"
                name="fileOption1"
                type="file"
                onChange={(event) => {
                  setFieldValue("fileOption1", event.currentTarget.files[0]);
                  setFieldValue(
                    "imageOption1",
                    event.currentTarget.files[0]
                      ? event.currentTarget.files[0].name
                      : ""
                  );
                }}
                onBlur={handleBlur}
                custom
              />
              <ErrorMessage
                className="text-danger"
                name="fileOption1"
                component="div"
              />
            </Form.Group>
          </Form.Row>
          {/* image option 2 */}
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>
                Opción 2
                <strong className="text-danger" title="Requerido">
                  *
                </strong>
                <small className="ml-1">(.jpg, .jpeg, .gif y .png)</small>
                {option2ImageUrl &&
                  !values.imageOption2 &&
                  !values.fileOption2 && (
                    <>
                      <br />
                      <ImageFromFirebase
                        className="mt-2"
                        height="85"
                        path={option2ImageUrl}
                        width="85"
                      />
                    </>
                  )}
              </Form.Label>
              <Form.File
                encType="multipart/form-data"
                accept="image/*"
                label={values.imageOption2 ? values.imageOption2 : ""}
                data-browse="Buscar"
                id="fileOption2"
                name="fileOption2"
                type="file"
                onChange={(event) => {
                  setFieldValue("fileOption2", event.currentTarget.files[0]);
                  setFieldValue(
                    "imageOption2",
                    event.currentTarget.files[0]
                      ? event.currentTarget.files[0].name
                      : ""
                  );
                }}
                onBlur={handleBlur}
                custom
              />
              <ErrorMessage
                className="text-danger"
                name="fileOption2"
                component="div"
              />
            </Form.Group>
          </Form.Row>
          {/* image option 3 */}
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>
                Opción 3
                <strong className="text-danger" title="Requerido">
                  *
                </strong>
                <small className="ml-1">(.jpg, .jpeg, .gif y .png)</small>
                {option3ImageUrl &&
                  !values.imageOption3 &&
                  !values.fileOption3 && (
                    <>
                      <br />
                      <ImageFromFirebase
                        className="mt-2"
                        height="85"
                        path={option3ImageUrl}
                        width="85"
                      />
                    </>
                  )}
              </Form.Label>
              <Form.File
                encType="multipart/form-data"
                accept="image/*"
                label={values.imageOption3 ? values.imageOption3 : ""}
                data-browse="Buscar"
                id="fileOption3"
                name="fileOption3"
                type="file"
                onChange={(event) => {
                  setFieldValue("fileOption3", event.currentTarget.files[0]);
                  setFieldValue(
                    "imageOption3",
                    event.currentTarget.files[0]
                      ? event.currentTarget.files[0].name
                      : ""
                  );
                }}
                onBlur={handleBlur}
                custom
              />
              <ErrorMessage
                className="text-danger"
                name="fileOption3"
                component="div"
              />
            </Form.Group>
          </Form.Row>
          {/* image option 4 */}
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>
                Opción 4
                <strong className="text-danger" title="Requerido">
                  *
                </strong>
                <small className="ml-1">(.jpg, .jpeg, .gif y .png)</small>
                {option4ImageUrl &&
                  !values.imageOption4 &&
                  !values.fileOption4 && (
                    <>
                      <br />
                      <ImageFromFirebase
                        className="mt-2"
                        height="85"
                        path={option4ImageUrl}
                        width="85"
                      />
                    </>
                  )}
              </Form.Label>
              <Form.File
                encType="multipart/form-data"
                accept="image/*"
                label={values.imageOption4 ? values.imageOption4 : ""}
                data-browse="Buscar"
                id="fileOption4"
                name="fileOption4"
                type="file"
                onChange={(event) => {
                  setFieldValue("fileOption4", event.currentTarget.files[0]);
                  setFieldValue(
                    "imageOption4",
                    event.currentTarget.files[0]
                      ? event.currentTarget.files[0].name
                      : ""
                  );
                }}
                onBlur={handleBlur}
                custom
              />
              <ErrorMessage
                className="text-danger"
                name="fileOption4"
                component="div"
              />
            </Form.Group>
          </Form.Row>
          {/* answers */}
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>
                Respuesta
                <strong className="text-danger" title="Requerido">
                  *
                </strong>{" "}
                <small>
                  (Escribe un número del 1 al 4 haciendo referencia a la opción
                  correcta)
                </small>
              </Form.Label>
              <Form.Control
                maxLength="250"
                type="text"
                name="qCorrectAnswers"
                value={values.qCorrectAnswers}
                onChange={handleChange}
                onBlur={handleBlur}
                isValid={touched.qCorrectAnswers && !errors.qCorrectAnswers}
                isInvalid={touched.qCorrectAnswers && !!errors.qCorrectAnswers}
              />
              <ErrorMessage
                className="text-danger"
                name="qCorrectAnswers"
                component="div"
              />
            </Form.Group>
          </Form.Row>
          {/* qComment */}
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>
                Comentario{" "}
                <small>(Utiliza el símbolo \n para saltos de línea)</small>
              </Form.Label>
              <Form.Control
                maxLength="500"
                type="text"
                as="textarea"
                rows="1"
                name="qComment"
                value={values.qComment}
                onChange={handleChange}
                onBlur={handleBlur}
                isValid={touched.qComment && !errors.qComment}
                isInvalid={touched.qComment && !!errors.qComment}
              />
              <ErrorMessage
                className="text-danger"
                name="qComment"
                component="div"
              />
            </Form.Group>
          </Form.Row>
          {/* buttons */}
          <Form.Group className="mt-1">
            <Button
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
              isSubmit
            />
          </Form.Group>
        </Form>
      )}
    </Formik>
  );
};

MultipleOptionWithImages.propTypes = {
  question: object,
};
