import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import { string } from "prop-types";
import cn from "classnames";
import { LanguageContext } from "../../../languages";

import styles from "./gobackbttn.module.scss";

export const GoBackBttn = ({ topicName, ...props }) => {
  const { displayText } = useContext(LanguageContext);

  const className = cn(styles.button, props.className);

  return (
    <Button href={`/course/#${topicName}`} className={className}>
      <i className="fas fa-long-arrow-alt-left mr-2" />
      {displayText("resultsPage.back")}
    </Button>
  );
};

GoBackBttn.propTypes = {
  topicName: string.isRequired,
};
