import React, { useCallback, useMemo } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import {
  CoursePage,
  DashboardPage,
  ExamPage,
  FreestylePage,
  MessagesPage,
  PaymentPage,
  ResultsPage,
} from "../student-pages";
import {
  CourseInfoPage,
  NoMatchPage,
  PrivacyPolicy,
  TermsAndConditions,
} from "../guest-pages";
import useLocalStorage from "use-local-storage";
import { LanguageContext, english, spanish } from "../languages";
import { get } from "lodash";

export const StudentNavigation = () => {
  const [language, setLanguage] = useLocalStorage("mexmaticas_language", "SPA");

  const displayText = useCallback(
    (key) => {
      switch (language) {
        case "SPA":
          return get(spanish, key, "");
        case "ENG":
          return get(english, key, "");
        default:
          return get(spanish, key, "");
      }
    },
    [language]
  );

  const contextValue = useMemo(
    () => ({ displayText, language, setLanguage }),
    [displayText, language, setLanguage]
  );

  return (
    <LanguageContext.Provider {...{ value: contextValue }}>
      <BrowserRouter>
        <Switch>
          {/* ================= legal ================= */}
          <Route
            exact
            path="/terms-and-conditions"
            component={TermsAndConditions}
          />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          {/* ================= public ================= */}
          <Route exact path="/dashboard" component={DashboardPage} />
          <Route
            exact
            path="/courses/:school"
            render={(props) => <CourseInfoPage routeProps={props} />}
          />
          <Route
            exact
            path="/payment/:school/:courseId"
            render={(props) => <PaymentPage routeProps={props} />}
          />
          {/* ================= student ================= */}
          <Route exact path="/messages" component={MessagesPage} />
          <Route
            exact
            path="/course"
            render={(props) => <CoursePage routeProps={props} />}
          />
          <Route exact path="/course/exam" component={ExamPage} />
          <Route exact path="/course/exam/results" component={ResultsPage} />
          <Route exact path="/course/freestyle" component={FreestylePage} />
          <Redirect from="/" to="/dashboard" />
          {/* ================= 404 ================= */}
          <Route component={NoMatchPage} />
        </Switch>
      </BrowserRouter>
    </LanguageContext.Provider>
  );
};
