import React, { forwardRef, useState } from "react";
import { Dropdown, Form } from "react-bootstrap";
import { Button } from "../buttons/Button";
import cn from "classnames";
import { arrayOf, bool, object, string } from "prop-types";
import { getAccessorValue } from "../../utils/helpers";
import { isEqual } from "lodash";
import { ScrollableDiv } from "../divs/ScrollableDiv";

import styles from "./dropdownwithsearch.module.scss";

const CustomToggle = forwardRef(({ children, onClick }, ref) => (
  <Button
    className={cn("bg-white d-flex text-left", styles.toggleButton)}
    hasShadow={false}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    <div className="d-flex w-100">
      <span className={cn(styles.anchorToggle)} ref={ref}>
        {children}
      </span>
      <i className="fas fa-angle-down ml-auto text-dark pt-2" />
    </div>
  </Button>
));

const CustomMenu = forwardRef(({ children, className }, ref) => {
  const [value, setValue] = useState("");

  return (
    <ScrollableDiv className={className} ref={ref}>
      <Form.Control
        autoFocus
        className="mx-3 my-2 w-75"
        onChange={(e) => setValue(e.target.value)}
        placeholder="Buscar por nombre..."
        value={value}
      />
      <ul className="list-unstyled">
        {React.Children.toArray(children).filter(
          (child) =>
            !value || child.props.children.toLowerCase().startsWith(value)
        )}
      </ul>
    </ScrollableDiv>
  );
});

export const DropdownWithSearch = ({
  data,
  handleSelectValue,
  hasBlank = false,
  isRequired = false,
  label,
  labelAccessor,
  selectedValue,
  toggleText = "Elige...",
  valueAccessor,
}) => {
  const [selected, setSelected] = useState(null);

  const getLabelFromSelectedValue = () => {
    if (!selectedValue) return {};
    const _item = (data || []).find((v) => isEqual(v._id, selectedValue));
    const _label = getAccessorValue(_item, labelAccessor);
    const _value = getAccessorValue(_item, valueAccessor);
    return { _label, _value };
  };

  const { _label, _value } = getLabelFromSelectedValue();

  const _selectedLabel = selectedValue ? _label : selected?.label;
  const _selectedValue = selectedValue ? _value : selected?.value;
  const _handleSelect = handleSelectValue ?? setSelected;

  return (
    <>
      {label && (
        <Form.Label>
          {label}
          {isRequired && (
            <strong className="text-danger" title="Requerido">
              *
            </strong>
          )}
        </Form.Label>
      )}
        <Dropdown>
          <Dropdown.Toggle as={CustomToggle}>
            {_selectedLabel ? _selectedLabel : toggleText}
          </Dropdown.Toggle>

          <Dropdown.Menu as={CustomMenu} className="w-100">
            {hasBlank && (
              <Dropdown.Item
                value={null}
                onClick={() => _handleSelect(null)}
                active={!_selectedValue}
              >
                Ninguno
              </Dropdown.Item>
            )}
            {(data || []).map((item, idx) => {
              const value = getAccessorValue(item, valueAccessor);
              const label = getAccessorValue(item, labelAccessor);
              return (
                <Dropdown.Item
                  key={idx}
                  value={value}
                  onClick={() => _handleSelect({ value, label })}
                  active={isEqual(value, _selectedValue)}
                >
                  {label}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
    </>
  );
};

DropdownWithSearch.propTypes = {
  data: arrayOf(object),
  disabled: bool,
  hasBlank: bool,
  isRequired: bool,
  label: string,
  labelAccessor: string.isRequired,
  toggleText: string,
  valueAccessor: string.isRequired,
};
