import React, { useContext } from "react";
import { object, string } from "prop-types";
import { Card } from "../../../components";
import { isEqual } from "lodash";
import { LanguageContext } from "../../../languages";

export const DashboardClassroomCard = React.memo(({ classroom, imageName }) => {
  const { displayText } = useContext(LanguageContext);

  return (
    <Card
      {...{
        imageName,
        ...(classroom?.institution && { title: classroom?.name }),
      }}
    >
      {classroom.name && (
        <span className="d-block">{classroom.institution}</span>
      )}
      {classroom.teacher && (
        <span>
          {`${
            isEqual(classroom.teacherGender, "man")
              ? displayText("dashboard.dashboardClassroomCard.male")
              : displayText("dashboard.dashboardClassroomCard.female")
          } ${classroom.teacher}`}
        </span>
      )}
    </Card>
  );
});

DashboardClassroomCard.propTypes = {
  classroom: object.isRequired,
  imageName: string,
};

DashboardClassroomCard.displayName = "DashboardClassroomCard";
