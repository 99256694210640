import React, { useContext } from "react";
import { StudentLayout } from "../../components";
import { Image } from "react-bootstrap";
import { LanguageContext } from "../../languages";

export const NoMatchPage = () => {
  const { displayText } = useContext(LanguageContext);

  return (
    <StudentLayout isContainer={false}>
      <div className="p-4 mt-4 text-center">
        <Image
          className="mb-3"
          height="135"
          src="/images/notfound.png"
          width="135"
        />
        <h1 className="display-4 mb-0">404</h1>
        <h4>{displayText("noMatch.title")}</h4>
        <p>
          {displayText("noMatch.msg1")}{" "}
          <a href="/" className="text-success">
            {displayText("noMatch.msg2")}
          </a>
        </p>
      </div>
    </StudentLayout>
  );
};
