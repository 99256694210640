import React, { memo, useContext } from "react";
import { Col, Container, Nav, Navbar } from "react-bootstrap";
import { CoursesDropdown, JoinClassroom, StudentDropdown } from "./components";
import { LanguageSwitch } from "../../../language-switch/LanguageSwitch";
import { array, bool, object } from "prop-types";
import cn from "classnames";
import { LanguageContext } from "../../../../languages";

import styles from "./navbar.module.scss";

export const NavBar = memo(
  ({ availableCourses, canTranslate, hasPendingMessages, isZen, student }) => {
    const { displayText } = useContext(LanguageContext);

    const Logo = () => (
      <a
        {...{
          className: cn(styles.logo, isZen && styles.zen),
          ...(!isZen && { href: "/" }),
        }}
      >
        MeXmáticas
      </a>
    );

    const SmallScreenDiv = () => {
      const Header = ({ text }) => (
        <h6 className="dropdown-header px-0 pb-0" style={{ color: "#3d4d53" }}>
          {text}
        </h6>
      );

      return (
        <div className="d-block d-lg-none">
          {student ? (
            <>
              <Header
                text={displayText("nav.smallScreenDiv.classroomHeader")}
              />
              <JoinClassroom />
            </>
          ) : (
            <>
              <Header text="CURSOS" />
              <CoursesDropdown {...{ availableCourses, isZen }} />
            </>
          )}
          <Header text={displayText("nav.smallScreenDiv.myUserHeader")} />
          <StudentDropdown {...{ hasPendingMessages, isZen, student }} />
        </div>
      );
    };

    const LargeScreenDiv = () => (
      <div className="d-none d-lg-block">
        <StudentDropdown {...{ hasPendingMessages, isZen, student }} />
      </div>
    );

    return (
      <Navbar
        className={cn("shadow", styles.container)}
        collapseOnSelect
        expand="lg"
        fixed="top"
        variant="dark"
      >
        <Navbar.Brand className="d-block d-lg-none">
          <Logo />
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse>
          <Container className="px-0 px-lg-3">
            <Nav as={Col} className="d-none d-lg-block pr-0">
              <div className="d-flex align-items-center">
                {student ? (
                  <JoinClassroom {...{ isZen }} />
                ) : (
                  <CoursesDropdown {...{ availableCourses, isZen }} />
                )}
                {canTranslate && <LanguageSwitch className="ml-4" />}
              </div>
            </Nav>
            <Nav as={Col} className="d-none d-lg-block text-center pr-0">
              <Navbar.Brand>
                <Logo />
              </Navbar.Brand>
            </Nav>
            <Nav
              as={Col}
              className="justify-content-end align-items-lg-end mt-2 mt-lg-0 pr-0"
            >
              {student && (
                <>
                  <SmallScreenDiv />
                  <LargeScreenDiv />
                </>
              )}
              {!student && (
                <>
                  <Nav.Link
                    className={cn("mr-lg-4", styles.navbarItem)}
                    href="/login"
                  >
                    Inicia Sesión
                  </Nav.Link>
                  <Nav.Link className={styles.navbarItem} href="/signup">
                    Regístrate
                  </Nav.Link>
                  <Nav className="d-block d-lg-none">
                    <CoursesDropdown {...{ availableCourses, isZen }} />
                  </Nav>
                </>
              )}
            </Nav>
          </Container>
        </Navbar.Collapse>
      </Navbar>
    );
  }
);

NavBar.propTypes = {
  availableCourses: array,
  canTranslate: bool,
  hasPendingMessages: bool,
  isZen: bool,
  student: object,
};

NavBar.displayName = "NavBar";
