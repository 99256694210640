import React, { memo, useContext, useEffect, useState } from "react";
import { Accordion, Button, Card, Col, Row } from "react-bootstrap";
import { array, object } from "prop-types";
import { DifficultyStars, FreestyleCard, NotAvailableBttn } from "..";
import { useDispatch, useSelector } from "react-redux";
import * as examActions from "../../../../redux/actions/exam";
import { isEqual } from "lodash";
import cn from "classnames";

import styles from "./examsaccordion.module.scss";
import { EXAM_VALUES } from "../../../../utils/constants";
import { LanguageContext } from "../../../../languages";
import { getExamsDescription } from "../../../../utils/helpers";

export const ExamsAccordion = memo(
  ({ exams, freestyle, reward, topicId, topicName }) => {
    const [selected, setSelected] = useState();

    const dispatch = useDispatch();
    const { displayText, language } = useContext(LanguageContext);
    const exam = useSelector((state) => state.exam);

    useEffect(() => {
      if (!exam) return;
      if (exam.name !== displayText("coursePage.fastMode"))
        window.location.href = "/course/exam";
    }, [displayText, exam]);

    const ArrowIcon = ({ isOpen }) => {
      if (isOpen)
        return <i className={cn("fas fa-chevron-up", styles.arrowIcon)} />;
      if (!isOpen)
        return <i className={cn("fas fa-chevron-down", styles.arrowIcon)} />;
    };

    const Toggle = ({ exam, index }) => {
      const { hasPerfectGrade, highestGrade, isAvailable, name } = exam;
      return (
        <>
          <ArrowIcon isOpen={isEqual(selected, index)} />
          <strong style={{ color: "#0f5257" }}>{name}</strong>
          {highestGrade >= EXAM_VALUES.GRADE_TO_APPROVE && (
            <i
              className="fas fa-check-circle text-warning ml-2"
              title={displayText("coursePage.passed")}
            />
          )}
          {hasPerfectGrade && (
            <i
              className="fas fa-crown text-warning ml-2"
              title={displayText("coursePage.perfectScore")}
            />
          )}{" "}
          {!isAvailable && (
            <i
              className="fas fa-lock ml-2 text-danger"
              title={displayText("coursePage.blocked")}
            />
          )}
        </>
      );
    };

    const UnderConstruction = () => (
      <div className={cn("text-center", styles.underConstructionDiv)}>
        <h4 className="mb-3">{displayText("coursePage.underConstruction")}</h4>
        <i
          className={cn(
            "fas fa-exclamation-triangle text-warning",
            styles.underConstruction
          )}
        />
      </div>
    );

    const CardContent = ({ exam }) => {
      const { qCounter, questions } = exam;
      const isUnderConstruction = qCounter > questions;
      if (isUnderConstruction) return <UnderConstruction />;
      return (
        <div className="p-4">
          <h3 className={cn("mb-2", styles.examName)}>{exam.name}</h3>
          <DifficultyStars difficulty={exam.difficulty} />
          <p className="mb-4">
            {getExamsDescription(exam.description, language)}
          </p>
          <Row className="mb-4">
            <Col className="text-center">
              <strong
                className="mb-0 rounded text-light px-3 bg-info shadow"
                style={{ fontSize: 45 }}
              >
                <span title={displayText("coursePage.cardContent.higherGrade")}>
                  {exam.highestGrade}
                </span>
              </strong>
              <strong className="d-block">
                {displayText("coursePage.grade")}
              </strong>
            </Col>
            <Col className="text-center">
              <strong
                className="mb-0 rounded text-light px-3 bg-info shadow"
                style={{ fontSize: 45 }}
              >
                <span title={displayText("coursePage.cardContent.attempts")}>
                  {exam.attemptsCounter}
                </span>
              </strong>
              <strong className="d-block">
                {displayText("coursePage.attempts")}
              </strong>
            </Col>
          </Row>
          {exam.isAvailable ? (
            <Button
              className="shadow genericButton"
              onClick={() => handleBeginExam(exam)}
            >
              {displayText("coursePage.start")}
            </Button>
          ) : (
            <NotAvailableBttn />
          )}
        </div>
      );
    };

    const handleBeginExam = (exam) => {
      const { _id, name, difficulty, duration, qCounter } = exam;
      dispatch(
        examActions.setExam({
          _id,
          difficulty,
          duration,
          name,
          qCounter,
          reward,
          topicId,
          topicName,
        })
      );
    };

    return (
      <Accordion className="shadow-sm">
        {exams
          .sort((a, b) => a.examOrderNumber - b.examOrderNumber)
          .map((exam, idx) => (
            <React.Fragment key={idx}>
              <Card>
                <Card.Header className={styles.cardHeader}>
                  <Accordion.Toggle
                    as={Button}
                    className={cn("p-0 text-left", styles.accordionToggle)}
                    eventKey={idx}
                    variant="link"
                    onClick={() =>
                      setSelected(!isEqual(selected, idx) ? idx : null)
                    }
                  >
                    <Toggle
                      {...{
                        exam,
                        index: idx,
                        key: idx,
                      }}
                    />
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey={idx}>
                  <Card.Body className="p-0">
                    <CardContent {...{ exam }} />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* only show freestyle if student has the reward AND it has to be the last card */}
              {/* DEPRECATED */}
              {false &&
                isEqual(exams.length, idx + 1) &&
                freestyle.isAvailable && (
                  <FreestyleCard
                    topicName={topicName}
                    topicId={topicId}
                    freestyle={freestyle}
                  />
                )}
            </React.Fragment>
          ))}
      </Accordion>
    );
  }
);

ExamsAccordion.propTypes = {
  exams: array.isRequired,
  freestyle: object.isRequired,
  reward: object.isRequired,
};

ExamsAccordion.displayName = "ExamsAccordion";
