import React, { useContext, useState } from "react";
import { Button } from "react-bootstrap";
import { string } from "prop-types";
import { AlertModal } from "../../";
import { LanguageContext } from "../../../languages";

export const ExitButton = React.memo(({ url }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { displayText } = useContext(LanguageContext);

  return (
    <>
      <Button
        size="sm"
        variant="danger"
        className="shadow-sm"
        onClick={handleShow}
      >
        {displayText("examPage.questionsContainer.exit.abandon")}
      </Button>

      <AlertModal image="/images/warning.png" show={show}>
        <h5 className="text-dark mb-3 mt-3">
          {displayText("examPage.questionsContainer.exit.message")}
        </h5>
        <div className="d-flex flex-row justify-content-center mt-4">
          <Button variant="dark shadow-sm" onClick={handleClose}>
            {displayText("examPage.questionsContainer.exit.cancel")}
          </Button>
          <Button
            variant="danger shadow-sm ml-2"
            onClick={() => (window.location.href = url)}
          >
            {displayText("examPage.questionsContainer.exit.abandon")}
            <i className="fas fa-door-open ml-1" />
          </Button>
        </div>
      </AlertModal>
    </>
  );
});

ExitButton.propTypes = {
  url: string.isRequired,
};

ExitButton.displayName = "ExitButton";
