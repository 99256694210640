import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { StudentLayout } from "../../components";
import { Container, Spinner } from "react-bootstrap";
import { fetchExamInfo } from "../../services";
import { QuestionsContainer } from "../../components";
import { useClassroom } from "../hooks/useClassroom";
import { LanguageContext } from "../../languages";

export const ExamPage = () => {
  const [exam, setExam] = useState();

  const { displayText } = useContext(LanguageContext);

  const reduxexam = useSelector((state) => state.exam);
  const course = useSelector((state) => state.course);

  const { getTeacherNames, isPartOfClassroom } = useClassroom(course?._id);

  useEffect(() => {
    try {
      fetchExamInfo(reduxexam._id).then((res) => {
        const realQuestionsCounter = res.data.questions.length;
        if (realQuestionsCounter < reduxexam.qCounter) {
          alert(displayText("examPage.alert"));
          window.location.href = "/course";
        } else {
          setExam(res.data);
        }
      });
    } catch (err) {
      console.log(err);
      alert(displayText("examPage.alert"));
      window.location.href = "/course";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StudentLayout isZen={true}>
      {exam ? (
        <>
          <Container>
            <h2 className="mt-4">
              <strong>{exam.name}</strong>
            </h2>
          </Container>
          <QuestionsContainer
            getTeacherNames={getTeacherNames}
            questions={exam.questions}
            isPartOfClassroom={isPartOfClassroom}
          />
          <br />
          <br />
        </>
      ) : (
        <div className="text-center mt-4 pt-4">
          <Spinner animation="border" variant="success" />
        </div>
      )}
    </StudentLayout>
  );
};
