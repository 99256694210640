import React, { memo, useContext } from "react";
import { NavDropdown } from "react-bootstrap";
import { bool, object } from "prop-types";
import { useFirebase } from "../../../../../firebase/useFirebase";
import { LanguageContext } from "../../../../../languages";

import styles from "./navbar.module.scss";

export const StudentDropdown = memo(
  ({ hasPendingMessages, isZen, student }) => {
    const { displayText } = useContext(LanguageContext);
    const { logoutUser } = useFirebase();

    const getStudentEmail = () => {
      if (!student) return null;
      if (isZen) return <s>{student.email}</s>;
      return <span>{student.email}</span>;
    };

    const RedDot = () => (
      <small>
        <i
          className="fas fa-circle ml-1"
          style={{ color: "#dc3545", textAlign: "center" }}
        />
      </small>
    );

    return (
      <NavDropdown
        alignRight
        className={styles.navDropdownToggle}
        disabled={isZen}
        title={
          <span className={styles.navDropdownText}>
            {getStudentEmail()}
            <i
              className="fas fa-chevron-down ml-1"
              style={{ fontSize: "13px" }}
            />
          </span>
        }
      >
        <NavDropdown.Item href="/dashboard" className="dropdownItem">
          {displayText("nav.userDropdown.myCourses")}
        </NavDropdown.Item>
        <NavDropdown.Item href="/messages" className="dropdownItem">
          {displayText("nav.userDropdown.myMessages")}
          {hasPendingMessages && <RedDot />}
        </NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item
          onClick={() => logoutUser("/")}
          className="dropdownItem"
        >
          {displayText("nav.userDropdown.signout")}
        </NavDropdown.Item>
      </NavDropdown>
    );
  }
);

StudentDropdown.propTypes = {
  hasPendingMessages: bool,
  isZen: bool,
  student: object,
};

StudentDropdown.displayName = "StudentDropdown";
