import React, { memo, useState } from "react";
import {
  any,
  array,
  bool,
  elementType,
  func,
  oneOf,
  oneOfType,
  shape,
  string,
} from "prop-types";
import { Col, Row } from "react-bootstrap";
import { useAdminRow } from "../../hooks/useAdminRow";
import { Button, Tooltip } from "../";
import { splitArray } from "../../utils/helpers";

export const AdminRow = memo(
  ({ button, icon, isCollapsable = false, list, rowTitle, tooltip, value }) => {
    const [isCollapsed, setIsCollapsed] = useState(isCollapsable);

    const {
      renderButton,
      renderDraggableList,
      renderIcon,
      renderList,
      renderListWithModals,
    } = useAdminRow();

    const renderTypeList = (_list) => {
      if (_list?.onOrderChange) return renderDraggableList(_list);
      if (_list?.modal?.labelAccessor && _list?.modal?.renderModalBody)
        return renderListWithModals(_list);
      return renderList(_list);
    };

    const renderTypeButton = () => renderButton(button);

    const isValue = !!value;
    const isButton = button?.label && button?.onClick;
    const isList = Boolean(list && list.data);

    const [firstHalf, secondHalf] = splitArray(list?.data);

    return (
      <Row className="mb-2">
        <Col>
          <span className="text-muted">
            {rowTitle.toUpperCase()}
            {tooltip && (
              <Tooltip className="ml-1" text={tooltip} variant="main" />
            )}
            {isCollapsable && (
              <Button
                className="ml-1"
                onClick={() => setIsCollapsed((prevState) => !prevState)}
                isAnchor
              >
                <i
                  className={
                    isCollapsed ? "fas fa-chevron-down" : "fas fa-chevron-up"
                  }
                />
              </Button>
            )}
          </span>
          {!isCollapsed && (
            <div className="d-flex d-row bg-light p-2 rounded">
              <strong className="w-100">
                {isValue && !isButton && !isList && value}
                {!isValue && isButton && !isList && renderTypeButton()}
                {!isValue && !isButton && isList && (
                  <>
                    {list?.splitListInColumns ? (
                      <Row>
                        <Col>
                          {renderTypeList({ ...list, data: firstHalf })}
                        </Col>
                        <Col>
                          {renderTypeList({ ...list, data: secondHalf })}
                        </Col>
                      </Row>
                    ) : (
                      renderTypeList(list)
                    )}
                  </>
                )}
                {icon && <span>{renderIcon(icon, value)}</span>}
              </strong>
            </div>
          )}
        </Col>
      </Row>
    );
  }
);

const iconType = shape({
  getLink: func,
  getTooltipData: func,
  hoverText: string,
  isDangerKey: string,
  isDisabled: bool,
  modal: shape({
    Form: oneOfType([elementType, func]),
    initialValue: any,
    size: oneOf(["sm", "md", "lg", "xl"]),
    title: oneOfType([func, string]),
  }),
  onClick: func,
  svg: oneOf(["add", "anchor", "chart", "copy", "delete", "edit", "popup"]),
});

export const listTypes = shape({
  accessor: oneOfType([array, string]),
  data: array,
  icon: iconType,
  isAnchor: bool,
  modalContent: shape({
    labelAccessor: string,
    renderModalBody: func,
  }),
  onOrderChange: func,
  sortBy: oneOfType([array, bool, string]),
});

AdminRow.propTypes = {
  button: shape({
    label: string,
    onClick: func,
  }),
  icon: iconType,
  isCollapsable: bool,
  list: listTypes,
  rowTitle: string.isRequired,
  tooltip: string,
  value: any,
};

AdminRow.displayName = "AdminRow";
