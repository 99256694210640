import React, { useContext } from "react";
import { formatDate } from "../../../utils/helpers";
import { LanguageContext } from "../../../languages";

export const LastVisited = React.memo(({ date }) => {
  const { displayText } = useContext(LanguageContext);

  const formattedDate = date
    ? formatDate(date, "LLLL")
    : displayText("coursePage.freestyle.noAttempts");

  return (
    <span
      style={{ fontSize: "14px" }}
      title={displayText("coursePage.freestyle.lastAttempt")}
    >
      <i className="fas fa-calendar-alt mr-2" />
      {formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1)}
    </span>
  );
});

LastVisited.displayName = "LastVisited";
