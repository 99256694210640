import React from "react";
import { get, isEmpty, isEqual, round } from "lodash";
import cn from "classnames";
import moment from "moment";
import "moment/locale/es";

export const getUserFromEmail = (email) => email?.split("@", 1)[0];

export const askUserToConfirm = (message, callback) =>
  isEqual(window.confirm(message), true) && callback();

export const getForwardUrl = (purchase) => {
  const isDev = isEqual(process.env.NODE_ENV, "development");

  let url;

  if (isDev) {
    url = purchase
      ? `http://localhost:3000/payment/${purchase.school}/${purchase.courseId}`
      : "http://localhost:3000/dashboard";
  } else {
    url = purchase
      ? `https://www.mexmaticas.com.mx/${purchase.school}/${purchase.courseId}`
      : "https://www.mexmaticas.com.mx/dashboard";
  }

  return url;
};

export const formatDate = (str, format) => moment(str).format(format);

export const convertToUpperCase = (str) =>
  String(str).toLocaleUpperCase().trim();

// asc only
export const sortArrayBy = (arr = [], key) => {
  if (isEmpty(arr)) return [];
  if (isEqual(typeof arr[0], "string") || isEqual(typeof arr[0], "number"))
    return arr.sort();
  return arr.sort((a, b) => {
    const itemA = isEqual(typeof key, "string")
      ? get(a, key, "")
      : getAccessorValue(a, key);
    const itemB = isEqual(typeof key, "string")
      ? get(b, key, "")
      : getAccessorValue(b, key);
    if (isEqual(typeof itemA, "number") && isEqual(typeof itemB, "number"))
      return itemA - itemB;
    return convertToUpperCase(itemA) < convertToUpperCase(itemB) ? -1 : 1;
  });
};

export const isObject = (obj) =>
  Object.prototype.toString.call(obj) === "[object Object]";

export const isArray = (arr) => Array.isArray(arr);

export const isString = (str) => isEqual(typeof str, "string");

export const getFullName = (name, firstSurname, secondSurname) =>
  `${name ?? ""} ${firstSurname ?? ""} ${secondSurname ?? ""}`.trim();

export const getAccessorValue = (object, accessor) => {
  const accessorArr = isArray(accessor) ? accessor : [accessor];
  return accessorArr
    .reduce((acc, cv) => acc.concat(`${get(object, cv, "")} `), "")
    .trim();
};

export const getDifficultyNameInSpanish = (difficulty) => {
  switch (difficulty) {
    case "Basic":
      return "Básico";
    case "Basic-Intermediate":
      return "Básico-Intermedio";
    case "Intermediate":
      return "Intermedio";
    case "Intermediate-Advanced":
      return "Intermedio-Avanzado";
    case "Advanced":
      return "Avanzado";
    default:
      return difficulty;
  }
};

export const getSchoolNameSortValue = (school) => {
  switch (school) {
    case "Primaria":
      return 1;
    case "Secundaria":
      return 2;
    case "Preparatoria":
      return 3;
    case "Universidad":
      return 4;
    default:
      return 0;
  }
};

export const getSvg = (svg, className) => {
  switch (svg) {
    case "anchor":
      return <i className={cn("fas fa-paper-plane", className)} />;
    case "edit":
      return <i className={cn("fas fa-pen-alt", className)} />;
    case "delete":
      return <i className={cn("fas fa-times", className)} />;
    case "add":
      return <i className={cn("fas fa-plus-circle", className)} />;
    case "eye":
      return <i className={cn("fas fa-eye", className)} />;
    case "question":
      return <i className={cn("fa fa-question-circle", className)} />;
    case "copy":
      return <i className={cn("fas fa-copy", className)} />;
    case "chart":
      return <i className={cn("fa-solid fa-chart-line", className)} />;
    case "popup":
      return <i className={cn("fa-solid fa-window-restore", className)} />;
    default:
      return null;
  }
};

export const sortCourses = (courses = []) => {
  return courses.map((course) => {
    switch (course) {
      case "Primaria":
        return { label: course, orderNumber: 1 };
      case "Secundaria":
        return { label: course, orderNumber: 2 };
      case "Preparatoria":
        return { label: course, orderNumber: 3 };
      case "Universidad":
        return { label: course, orderNumber: 4 };
      default:
        return { label: "Otro", orderNumber: 5 };
    }
  });
};

export const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const getPercentage = (value1 = 0, value2 = 100) => {
  const percentage = `${round((value1 / value2) * 100, 2)}%`;
  return percentage;
};

export const splitArray = (array) => {
  if (isEmpty(array)) return [null, null];
  const middleIndex = Math.ceil(array.length / 2);
  const firstHalf = array.slice(0, middleIndex);
  const secondHalf = array.slice(middleIndex);
  return [firstHalf, secondHalf];
};

export const displaySubject = (subject, currentLanguage) => {
  if (currentLanguage === "SPA") return subject;
  switch (subject) {
    case "Álgebra":
      return "Algebra";
    case "Aritmética":
      return "Arithmetic";
    case "Cálculo Diferencial":
      return "Differential Calculus";
    case "Cálculo Integral":
      return "Integral Calculus";
    case "Funciones":
      return "Functions";
    case "Geometría":
      return "Geometry";
    case "Geometría Analítica":
      return "Analytic Geometry";
    case "Misceláneos":
      return "Miscellaneous";
    case "Pre-Cálculo":
      return "Precalculus";
    case "Probabilidad":
      return "Probability";
    case "Sistema de Medición":
      return "Systems of Measurement";
    case "Trigonometría":
      return "Trigonometry";
    default:
      return "";
  }
};

export const getExamsDescription = (description, currentLanguage) => {
  if (!!description) return description;
  if (currentLanguage === "SPA") return "Descripción del examen.";
  return "Exam's description.";
};

export const displayDichotomousQuestion = (
  questionType,
  textChoices,
  value,
  language
) => {
  const isDichotomous =
    questionType === "text" &&
    textChoices.length === 2 &&
    textChoices.includes("Falso") &&
    textChoices.includes("Verdadero");

  return !isDichotomous || language === "SPA"
    ? value
    : value === "Verdadero"
    ? "True"
    : "False";
};

export const displayTrueOrFalse = (value, language) => {
  if (language === "SPA") return value;
  if (value === "Verdadero") return "True";
  if (value === "Falso") return "False";
  return value;
};

export const getLanguage = (lang) =>
  lang === "english" ? "Inglés" : "Español";
