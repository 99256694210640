import React, { useContext } from "react";
import { bool, string } from "prop-types";
import { Button } from "react-bootstrap";
import { AlertModal } from "../../";
import { LanguageContext } from "../../../languages";

export const TimeOutModal = React.memo(({ showTimeOut, url }) => {
  const { displayText } = useContext(LanguageContext);

  return (
    <AlertModal image="/images/timeover.png" show={showTimeOut}>
      <h3 className="text-dark mb-3">
        {displayText("examPage.questionsContainer.timeOutModal.title")}
      </h3>
      <div className="mt-4">
        <Button
          onClick={() => (window.location.href = url)}
          className="shadow-sm genericButton"
        >
          {displayText("examPage.questionsContainer.timeOutModal.back")}
        </Button>
      </div>
    </AlertModal>
  );
});

TimeOutModal.propTypes = {
  showTimeOut: bool,
  url: string,
};

TimeOutModal.displayName = "TimeOutModal";
