export const spanish = {
  nav: {
    code: {
      joinLabel: "Unirte a Salón",
      modalTitle: "Ingresa el código del salón.",
      modalSubtitle: "Código",
      joinButton: "Unirse",
    },
    smallScreenDiv: {
      classroomHeader: "SALÓN",
      myUserHeader: "MI USUARIO",
    },
    userDropdown: {
      myCourses: "Mis Cursos",
      myMessages: "Mis Mensajes",
      signout: "Cerrar Sesión",
    },
  },
  dashboard: {
    coursesTitle: "Cursos",
    classroomsTitle: "Salones",
    noCourses: "No tienes cursos...",
    dashboardClassroomCard: {
      male: "Mtro.",
      female: "Mtra.",
    },
  },
  messages: {
    title: "Mensajes",
    empty: "No tienes mensajes...",
    conversationModal: {
      title: "Mensajes",
      sender: "Remitente",
      recipient: "Destinatario",
      origin: "Origen",
      date: "Fecha",
      onDeleteConfirm: "¿Estás seguro que quieres eliminar este mensaje?",
      delete: "Borrar",
      send: "Enviar",
    },
  },
  coursePage: {
    alert: "Ocurrió un error al cargar la información del curso.",
    back: "Regresar a mis cursos",
    topics: "Temario",
    medals: "Medallas",
    material: "Material",
    exams: "Exámenes",
    fastMode: "Modo Rápido",
    passed: "Aprobado",
    perfectScore: "Calificación perfecta",
    blocked: "Bloqueado",
    underConstruction: "En construcción...",
    cardContent: {
      higherGrade: "Calificación más alta",
      attempts: "Número de intentos",
    },
    levels: {
      basic: "Básico",
      basicIntermediate: "Básico-Intermedio",
      intermediate: "Intermedio",
      intermediateAdvanced: "Intermedio-Avanzado",
      advanced: "Avanzado",
      difficulty: "Dificultad",
    },
    grade: "Calificación",
    attempts: "Intentos",
    start: "Iniciar",
    notAvailable:
      "Para iniciar este examen es necesario aprobar el nivel previo con 8 o más",
    freestyle: {
      title: "Modo Rápido",
      description:
        "Contesta la mayor cantidad de preguntas que puedas en {1} minutos.",
      description2:
        "Los mejores 10 resultados serán mostrados en el cuadro de honor.",
      noAttempts: "No has presentado este examen",
      lastAttempt: "Último Intento",
      duration: "Duración",
      higherScore: "Puntuación más alta",
      points: "Puntos",
      numberOfAttempts: "Número de intentos",
      attempts: "Intentos",
      start: "Iniciar",
    },
    helpModal: {
      help: "Ayuda",
      message:
        "¿Necesitas ayuda? Utiliza el siguiente recuadro para hacerle llegar tu duda al maestro.",
      chooseTeacher: "Elige maestro:",
      messageSent: "Mensaje enviado.",
      cancel: "Cancelar",
      send: "Enviar",
      required: "Requerido",
    },
    blockedMedal: "Medalla bloqueada",
  },
  examPage: {
    alert:
      "Ocurrió un error con este examen. Ponte en contacto con tu maestro.",
    questionsContainer: {
      alert:
        "Ocurrió un error en el servidor, no se pudo registrar su calificación.",
      timer: {
        title: "Tiempo restante",
        minutes: "Minutos",
        seconds: "Segundos",
      },
      freestylePoints: "Puntos",
      progress: "Avance",
      point: "Punto",
      points: "Puntos",
      buttonFinish: "Finalizar",
      buttonNext: "Siguiente",
      exit: {
        message:
          "¿Estás seguro que deseas abandonar el examen? Tu avance será borrado y tendrás que empezar de nuevo.",
        cancel: "Cancelar",
        abandon: "Abandonar",
      },
      timeOutModal: {
        title: "Se ha acabado tu tiempo.",
        back: "Volver al curso",
      },
      scoreModal: {
        timeFinished: "Tu tiempo se ha terminado, tu puntuación final es:",
        back: "Volver al curso",
      },
      correct: "Correcto",
      incorrect: "Incorrecto",
    },
  },
  resultsPage: {
    back: "Regresar",
    grade: {
      score: "Tu calificación es...",
      corrects: "ACIERTOS",
      incorrects: "ERRORES",
    },
    results: {
      perfectGrade: "¡Calificación Perfecta!",
      crown: "Has recibido una corona por tu desempeño",
      satisfied: "Resultado Satisfactorio",
      veryGood: "¡Buen Trabajo!",
      notSatisfied: "No Satisfactorio",
      notSatisfiedMessage: "Debes obtener un mínimo de {1} para aprobar",
    },
    unlocked: {
      newExam: "¡Nuevo Examen!",
      msg1: "El examen",
      msg2: "ha sido desbloqueado.",
      congratulations: "¡Felicidades!",
      concluded: "Has concluido el tema",
      fastMode: "Modo Rápido",
    },
    myResults: {
      yourAnswers: "Tus respuestas fueron...",
      correct: "CORRECTO",
      yourAnswer: "Tu respuesta:",
      correctAnswer: "Respuesta correcta",
      clickToCheck: "Haz clic para ver respuesta correcta",
      incorrect: "INCORRECTO",
    },
  },
  noMatch: {
    title: "Error, la página que estás buscando no existe.",
    msg1: "Regresa a nuestra",
    msg2: "página principal",
  },
  freestylePage: {
    alert:
      "Ocurrió un error con este examen. Ponte en contacto con tu maestro.",
  },
  goTo: "Ir a",
  approved: "Aprobado",
};
