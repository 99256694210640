import React, { useEffect, useMemo, useState } from "react";
import { Chart as ChartJS, ArcElement, Legend, Title, Tooltip } from "chart.js";
import { Pie } from "react-chartjs-2";
import { Col, Container, Row } from "react-bootstrap";
import { Button } from "../../../../components";
import { isEqual } from "lodash";
import { getPercentage } from "../../../../utils/helpers";
import { EXAM_VALUES } from "../../../../utils/constants";

ChartJS.register(ArcElement, Legend, Title, Tooltip);

export const PieChart = ({ report = [], selectedTopic = "" }) => {
  const [activeExam, setActiveExam] = useState("");

  const chartExams = useMemo(() => {
    const arr = report?.reduce((acc, cv) => {
      const { attempts } = cv;
      const topics = attempts.map(({ examName }) => examName);
      acc.push(...topics);
      return acc;
    }, []);

    return [...new Set(arr)];
  }, [report]);

  const [
    passCounter,
    failCounter,
    approvedStudents,
    failedStudents,
    totalOfStudentsOfParticularExam,
  ] = useMemo(() => {
    let passCounter = 0;
    let failCounter = 0;
    let approvedStudents = "";
    let failedStudents = "";
    let totalOfStudentsOfParticularExam = 0;
    for (const student of report) {
      const studentName = student?.studentName;
      const grade = student?.attempts.find(({ examName }) =>
        isEqual(examName, activeExam)
      )?.grade;
      if (!grade && !isEqual(grade, 0)) continue;
      totalOfStudentsOfParticularExam++;
      if (grade >= EXAM_VALUES.GRADE_TO_APPROVE) {
        passCounter++;
        approvedStudents += `\n● ${studentName}`;
      } else {
        failCounter++;
        failedStudents += `\n● ${studentName}`;
      }
    }
    return [
      passCounter,
      failCounter,
      approvedStudents.trim(),
      failedStudents.trim(),
      totalOfStudentsOfParticularExam,
    ];
  }, [activeExam, report]);

  const data = useMemo(
    () => ({
      datasetIdKey: `pieChart${selectedTopic}`,
      labels: [
        `Aprobados [${getPercentage(
          passCounter,
          totalOfStudentsOfParticularExam
        )}]`,
        `Reprobados [${getPercentage(
          failCounter,
          totalOfStudentsOfParticularExam
        )}]`,
      ],
      datasets: [
        {
          backgroundColor: ["#7CB9E8", "#AA0000"],
          borderColor: ["black", "black"],
          borderWidth: 1,
          data: [passCounter, failCounter],
          radius: "90%",
        },
      ],
    }),
    [failCounter, passCounter, selectedTopic, totalOfStudentsOfParticularExam]
  );

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          font: {
            size: 14,
          },
        },
      },
      tooltip: {
        callbacks: {
          label: (context) => context?.label,
          footer: (context) => {
            if (context[0]?.label?.includes("Aprobados")) {
              return approvedStudents;
            } else {
              return failedStudents;
            }
          },
        },
      },
    },
  };

  const fallbackContent = (
    <div className="my-4">Hubo un error en la gráfica</div>
  );

  useEffect(() => setActiveExam(chartExams[0]), [chartExams]);

  return (
    <Container>
      <Row>
        <Col>
          <Pie
            {...{
              data,
              datasetIdKey: `pieChart${activeExam}`,
              fallbackContent,
              options,
            }}
          />
        </Col>
        <Col>
          <h4>Exámenes:</h4>
          <div className="d-flex flex-column">
            {chartExams.map((exam, idx) => (
              <Button
                className="d-block text-left"
                isActive={isEqual(activeExam, exam)}
                isAnchor
                key={idx}
                onClick={() =>
                  !isEqual(activeExam, exam) && setActiveExam(exam)
                }
                size="sm"
              >
                {`> ${exam}`}
              </Button>
            ))}
          </div>
        </Col>
      </Row>
    </Container>
  );
};
