import React, { useState, useEffect, useContext } from "react";
import { Button, CardColumns, Spinner } from "react-bootstrap";
import { StudentLayout, StudentTitle } from "../../components";
import { useSelector } from "react-redux";
import { fetchDashboard, fetchSchoolDropdownItems } from "../../services";
import { useDispatch } from "react-redux";
import * as courseActions from "../../redux/actions/course";
import * as examActions from "../../redux/actions/exam";
import { errorLogger } from "../../errors/errorLogger";
import { isEmpty } from "lodash";
import { DashboardCourseCard } from "./components/DashboardCourseCard";
import { useClassroom } from "../hooks/useClassroom";
import { EmptyBox } from "../components/EmptyBox";
import { DashboardClassroomCard } from "./components/DashboardClassroomCard";
import { sortCourses } from "../../utils/helpers";
import { LanguageContext } from "../../languages";

export const DashboardPage = () => {
  const [courses, setCourses] = useState();
  const [coursesForSale, setCoursesForSale] = useState();

  const { displayText } = useContext(LanguageContext);
  const dispatch = useDispatch();
  const student = useSelector((state) => state.student);
  const course = useSelector((state) => state.course);
  const exam = useSelector((state) => state.exam);

  const { myClassrooms } = useClassroom();

  const isLoading = !courses;
  const areCoursesEmpty = !isLoading && Boolean(!courses?.length);
  const thereAreCourses = Boolean(courses?.length);

  useEffect(() => {
    // clear redux stuff
    if (course) dispatch(courseActions.clearCourse());
    if (exam) dispatch(examActions.clearExam());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // fetch student's courses
    if (student) {
      fetchDashboard(student._id)
        .then((res) => setCourses(res.data.courses))
        .catch((err) => errorLogger(err));
    }
  }, [student, dispatch]);

  useEffect(() => {
    // fetch stuff only if there are no courses
    if (!areCoursesEmpty) return;
    fetchSchoolDropdownItems()
      .then((res) => setCoursesForSale(sortCourses(res.data)))
      .catch((err) => errorLogger(err));
  }, [areCoursesEmpty]);

  const renderCourses = (courses) => (
    <CardColumns>
      {courses.map((c) => (
        <DashboardCourseCard
          course={c}
          imageName="courses.png"
          key={c._id}
          onClick={() => console.log("click")}
        />
      ))}
    </CardColumns>
  );

  const renderEmptyCourses = () => (
    <div className="text-center mt-4">
      <EmptyBox text={displayText("dashboard.noCourses")} />
      <div className="d-flex mt-4 justify-content-center">
        {!isEmpty(coursesForSale) &&
          coursesForSale
            .sort((a, b) => a.orderNumber - b.orderNumber)
            .map((course, idx) => (
              <Button
                className="shadow mr-3 genericButton"
                href={`/courses/${course.label}`}
                key={idx}
              >
                {course.label}
              </Button>
            ))}
      </div>
    </div>
  );

  return (
    <StudentLayout hasScrollButton>
      {!isEmpty(myClassrooms) && (
        <section>
          <StudentTitle text={displayText("dashboard.classroomsTitle")} />
          <CardColumns>
            {myClassrooms.map((classroom, idx) => (
              <DashboardClassroomCard
                classroom={classroom}
                imageName="classrooms.png"
                key={idx}
              />
            ))}
          </CardColumns>
        </section>
      )}
      <section>
        <StudentTitle text={displayText("dashboard.coursesTitle")} />
        {isLoading && (
          <div className="text-center mt-4 pt-4">
            <Spinner animation="border" variant="success" />
          </div>
        )}
        {thereAreCourses && renderCourses(courses)}
        {areCoursesEmpty && renderEmptyCourses()}
      </section>
    </StudentLayout>
  );
};
