import React from "react";
import { StudentLayout } from "../../components";

import styles from "./legal.module.scss";

export const PrivacyPolicy = () => (
  <StudentLayout
    canTranslate={false}
    isContainer={false}
    hasScrollButton={true}
  >
    <section className={styles.wrapper}>
      <div className="text-center mb-4">
        <h1 className={styles.title}>Política de Privacidad</h1>
      </div>
      <p>
        Luis Rodrigo López Utrera mejor conocido como MeXmáticas, con domicilio
        en Av. Graciano Sánchez 775, colonia primero de mayo sur, Bocal del Río,
        Veracruz. México. y cuyo portal de internet{" "}
        <a href="https://www.mexmaticas.com.mx">
          https://www.mexmaticas.com.mx
        </a>
        , es el responsable del uso y protección de sus datos personales, y al
        respecto le informamos lo siguiente:
      </p>
      <h5>¿Para qué fines utilizaremos sus datos personales?</h5>
      <p>
        De ser estrictamente necesario para la presentación de información a
        directivos de instituciones educativas, los datos personales que
        recabamos de usted, los utilizaremos para las siguientes finalidades que
        son necesarias para el servicio que solicita:
      </p>
      <ul>
        <li>Respuesta a mensajes del formulario de contacto.</li>
        <li>Prestación de cualquier servicio solicitado.</li>
        <li>Presentación de información a padres de familia.</li>
        <li>Presentación de información a directivos.</li>
      </ul>
      <h5>¿Qué datos personales utilizaremos para estos fines?</h5>
      <p>
        Para llevar a cabo las finalidades descritas en el presente aviso de
        privacidad, utilizaremos los siguientes datos personales:
      </p>
      <ul>
        <li>Datos de identificación.</li>
        <li>Contacto de correo institucional.</li>
      </ul>
      <h5>
        ¿Cómo puede acceder, rectificar o cancelar sus datos personales, u
        oponerse a su uso o ejercer la revocación de consentimiento?
      </h5>
      <p>
        Usted tiene derecho a conocer qué datos personales tenemos de usted,
        para qué los utilizamos y las condiciones del uso que les damos
        (Acceso). Asimismo, es su derecho solicitar la corrección de su
        información personal en caso de que esté desactualizada, sea inexacta o
        incompleta (Rectificación); que la eliminemos de nuestros registros o
        bases de datos cuando considere que la misma no está siendo utilizada
        adecuadamente (Cancelación); así como oponerse al uso de sus datos
        personales para fines específicos (Oposición). Estos derechos se conocen
        como «Derechos ARCO».
      </p>
      <p>
        Para el ejercicio de cualquiera de los «Derechos ARCO», debe enviar una
        petición vía correo electrónico a{" "}
        <a href="mailto: contacto@mexmaticas.com.mx">
          contacto@mexmaticas.com.mx
        </a>{" "}
        y deberá contener:
      </p>
      <ul>
        <li>Nombre completo del titular.</li>
        <li>Domicilio.</li>
        <li>Teléfono.</li>
        <li>Correo electrónico usado en este sitio web.</li>
        <li>Copia de una identificación oficial adjunta.</li>
        <li>Asunto «Derechos ARCO».</li>
        <li>
          Descripción el objeto del escrito, los cuales pueden ser de manera
          enunciativa más no limitativa los siguientes: Revocación del
          consentimiento para tratar sus datos personales; y/o Notificación del
          uso indebido del tratamiento de sus datos personales; y/o Ejercitar
          sus «Derechos ARCO», con una descripción clara y precisa de los datos
          a Acceder, Rectificar, Cancelar o bien, Oponerse. En caso de
          Rectificación de datos personales, deberá indicar la modificación
          exacta y anexar la documentación soporte; es importante en caso de
          revocación del consentimiento, que tenga en cuenta que no en todos los
          casos podremos atender su solicitud o concluir el uso de forma
          inmediata, ya que es posible que por alguna obligación legal
          requiramos seguir tratando sus datos personales. Asimismo, usted
          deberá considerar que para ciertos fines, la revocación de su
          consentimiento implicará que no le podamos seguir prestando el
          servicio que nos solicitó, o la conclusión de su relación con
          nosotros.
        </li>
      </ul>
      <h5>¿En cuántos días le daremos respuesta a su solicitud?</h5>
      <p>10 días.</p>
      <h5>¿Por qué medio le comunicaremos la respuesta a su solicitud?</h5>
      <p>Al mismo correo electrónico de donde se envío la petición.</p>
      <h5>El uso de tecnologías de rastreo en nuestro portal de internet.</h5>
      <p>
        Le informamos que en nuestra página de internet utilizamos cookies, web
        beacons u otras tecnologías, a través de las cuales es posible
        monitorear su comportamiento como usuario de internet, así como
        brindarle un mejor servicio y experiencia al navegar en nuestra página.
        Los datos personales que obtenemos de estas tecnologías de rastreo son
        los siguientes:
      </p>
      <ul>
        <li>Identificadores.</li>
        <li>Nombre de usuario.</li>
        <li>Contraseñas de sesión.</li>
        <li>Región en la que se encuentra el usuario.</li>
        <li>Fecha y hora del inicio y final de una sesión de un usuario.</li>
        <li>Correo institucional.</li>
      </ul>
      <p>
        Estas cookies, web beacons y otras tecnologías pueden ser
        deshabilitadas. Para conocer cómo hacerlo, consulte el menú de ayuda de
        su navegador. Tenga en cuenta que, en caso de desactivar las cookies, es
        posible que no pueda acceder a ciertas funciones personalizadas en
        nuestros sitio web.
      </p>
      <h5>¿Cómo puede conocer los cambios en este aviso de privacidad?</h5>
      <p>
        El presente aviso de privacidad puede sufrir modificaciones, cambios o
        actualizaciones derivadas de nuevos requerimientos legales; de nuestras
        propias necesidades por los productos o servicios que ofrecemos; de
        nuestras prácticas de privacidad; de cambios en nuestro modelo de
        negocio, o por otras causas. Nos comprometemos a mantener actualizado
        este aviso de privacidad sobre los cambios que pueda sufrir y siempre
        podrá consultar las actualizaciones que existan en el sitio web{" "}
        <a href="https://www.mexmaticas.com.mx">
          https://www.mexmaticas.com.mx
        </a>
        .
      </p>
      <p className="text-center">
        <small>
          <i>Última actualización: 26/01/2023</i>
        </small>
      </p>
    </section>
  </StudentLayout>
);
