import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { AdminLoginPage } from "../admin-pages";
import { TeacherLoginPage } from "../teacher-pages";
import {
  CourseInfoPage,
  LandingPage,
  LoginPage,
  NoMatchPage,
  PrivacyPolicy,
  SignUpPage,
  TermsAndConditions,
} from "../guest-pages";

export const GuestNavigation = () => (
  <BrowserRouter>
    <Switch>
      {/* ================= legal ================= */}
      <Route
        exact
        path="/terms-and-conditions"
        component={TermsAndConditions}
      />
      <Route exact path="/privacy-policy" component={PrivacyPolicy} />
      {/* ================= guest ================= */}
      <Route exact path="/" component={LandingPage} />
      <Route exact path="/signup" component={SignUpPage} />
      <Route exact path="/login" component={LoginPage} />
      <Route
        exact
        path="/courses/:school"
        render={(props) => <CourseInfoPage routeProps={props} />}
      />
      <Redirect from="/dashboard" to="/" />
      <Redirect from="/course" to="/" />
      {/* ================= admin login only ================= */}
      <Route exact path="/admin" component={AdminLoginPage} />
      <Redirect from="/admin/*" to="/admin" />
      {/* ================= teacher login only ================= */}
      <Route exact path="/teacher" component={TeacherLoginPage} />
      <Redirect from="/teacher/*" to="/teacher" />
      {/* ================= 404 ================= */}
      <Route component={NoMatchPage} />;
    </Switch>
  </BrowserRouter>
);
