import React from "react";
import { isEmpty, isEqual } from "lodash";
import { getAccessorValue, sortArrayBy } from "../utils/helpers";
import {
  Button,
  DraggableList,
  IconButton,
  ListWithModals,
  ModalIconButton,
  Tooltip,
} from "../components";
import cn from "classnames";

export const useAdminRow = () => {
  const renderIcon = (icon, item) => {
    const isTooltip = !!icon.getTooltipData;
    const isLink = !!icon.getLink;
    const isFunc = !!icon.onClick;
    const isModal = !!icon.modal;

    if (isLink || isFunc) {
      return (
        <IconButton
          {...{
            hoverText: icon.hoverText,
            isDisabled: icon.isDisabled,
            svg: icon.svg,
            ...(icon.isDangerKey && {
              className: item[icon.isDangerKey] ? "text-danger" : "",
            }),
            ...(isFunc && { onClick: () => icon.onClick(item) }),
            ...(isLink && { href: icon.getLink(item) }),
          }}
        />
      );
    }

    if (isTooltip) {
      return (
        <Tooltip
          className="ml-1"
          text={icon.getTooltipData(item)}
          variant="main"
          svg="eye"
        />
      );
    }

    if (isModal) {
      const { Form, initialValue, size, title } = icon.modal;
      return (
        <ModalIconButton
          {...{
            Form: !isEqual(typeof Form, "function") ? Form : Form(item),
            formLabel: icon.hoverText,
            hoverText: icon.hoverText,
            isDisabled: icon.isDisabled,
            modalTitle: !isEqual(typeof title, "function")
              ? title
              : title(item),
            svg: icon.svg,
            ...(initialValue && { formInitialText: initialValue }),
            ...(size && { size }),
          }}
        />
      );
    }
  };

  const renderList = (list, className) => {
    if (isEmpty(list.data)) return <span>-</span>;
    const sortedData = list.sortBy
      ? sortArrayBy(list.data, list.sortBy)
      : list.data;
    return sortedData.map((item, idx) => {
      if (!item) return null;
      if (item.hasRef) {
        const scrollToRef = () => item.ref.current.scrollIntoView();
        return (
          <span className="d-block" key={idx}>
            <Button hoverText="Ir a preguntas" isAnchor onClick={scrollToRef}>
              {getAccessorValue(item, list.accessor).trim()}
            </Button>
            {list.icon && renderIcon(list.icon, item)}
          </span>
        );
      }
      return (
        <span className={cn("d-block", className)} key={idx}>
          {isEqual(typeof item, "string") && item}
          {isEqual(typeof item, "object") &&
            getAccessorValue(item, list.accessor)}
          {list.icon && renderIcon(list.icon, item)}
        </span>
      );
    });
  };

  const renderDraggableList = (list) => {
    if (isEmpty(list.data)) return <span>-</span>;
    return <DraggableList list={list} handleOnChange={list.onOrderChange} />;
  };

  const renderButton = (button) => {
    if (!button?.label || !button?.onClick) return;
    return (
      <Button
        {...{
          hoverText: "Consultar desempeño",
          onClick: button.onClick,
          size: "sm",
        }}
      >
        {button.label}
      </Button>
    );
  };

  const renderListWithModals = (list) => {
    if (
      isEmpty(list.data) ||
      !list.modal?.labelAccessor ||
      !list.modal?.renderModalBody
    )
      return <span>-</span>;
    return (
      <ListWithModals
        data={list.data}
        labelAccessor={list.modal.labelAccessor}
        renderModalBody={list.modal.renderModalBody}
      />
    );
  };

  return {
    renderButton,
    renderDraggableList,
    renderIcon,
    renderList,
    renderListWithModals,
  };
};
