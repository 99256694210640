import React from "react";
import { Badge, Card } from "react-bootstrap";
import { array, number, string } from "prop-types";
import cn from "classnames";

import styles from "./courseinfocard.module.scss";

export const CourseInfoCard = React.memo(({ lessonCounter, title, topics }) => (
  <div className="mb-4">
    <Card
      className={cn(
        "border",
        "h-100",
        "mr-0",
        "mr-lg-4",
        "rounded",
        "shadow-sm",
        styles.card
      )}
    >
      <Card.Body className="d-flex flex-column">
        <div className="d-flex flex-row">
          <h2 className={cn("mb-0", "pr-2", styles.title)}>{title}</h2>
          <Badge
            className={cn(
              "align-items-center",
              "d-flex",
              "ml-auto",
              styles.badge
            )}
          >
            {`${lessonCounter} ${
              lessonCounter === 1 ? "lección" : "lecciones"
            }`}
          </Badge>
        </div>
        <p className="mt-4">
          En este curso obtienes material didáctico, videos exclusivos,
          asistencia personalizada por parte de tu profesor y cientos de
          ejercicios sobre los siguientes temas:
        </p>
        {topics.map((l, idx) => {
          return (
            <div key={idx}>
              <i
                className={cn("fa-check-circle", "fas", "mr-2", styles.bullet)}
              />
              <span>{l}</span>
            </div>
          );
        })}
      </Card.Body>
    </Card>
  </div>
));

CourseInfoCard.propTypes = {
  lessonCounter: number.isRequired,
  title: string.isRequired,
  topics: array.isRequired,
};

CourseInfoCard.displayName = "CourseInfoCard";
