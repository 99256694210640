import React, { useContext } from "react";
import { Row, Col } from "react-bootstrap";
import { number } from "prop-types";
import "./grade.scss";
import { LanguageContext } from "../../../languages";

export const Grade = React.memo(({ grade, corrects, incorrects }) => {
  const { displayText } = useContext(LanguageContext);

  return (
    <>
      <h2 className="mt-3">{displayText("resultsPage.grade.score")}</h2>
      {/* calificación */}
      <div
        className="d-flex flex-column rounded justify-content-center align-items-center"
        id="gradecardstyle"
      >
        <h1 className="mb-0" style={{ fontSize: "9rem" }}>
          {grade}
        </h1>
      </div>
      {/* aciertos y errores */}
      <Row className="mt-3">
        <Col className="text-center p-3">
          <strong className="text-muted">
            {displayText("resultsPage.grade.corrects")}
          </strong>
          <h1 className="mb-0 text-success" style={{ fontSize: "4rem" }}>
            {corrects}
          </h1>
        </Col>
        <Col className="text-center p-3">
          <strong className="text-muted">
            {displayText("resultsPage.grade.incorrects")}
          </strong>
          <h1 className="mb-0 text-danger" style={{ fontSize: "4rem" }}>
            {incorrects}
          </h1>
        </Col>
      </Row>
    </>
  );
});

Grade.propTypes = {
  corrects: number.isRequired,
  grade: number.isRequired,
  incorrects: number.isRequired,
};

Grade.displayName = "Grade";
