import React, { useContext } from "react";
import { ProgressBar } from "react-bootstrap";
import { number } from "prop-types";
import { LanguageContext } from "../../../languages";

export const Progress = React.memo(({ current, total }) => {
  const { displayText } = useContext(LanguageContext);

  return (
    <ProgressBar
      animated
      className="w-100 rounded-0"
      max={total}
      min="1"
      now={current}
      style={{ height: "1.7rem" }}
      title={displayText("examPage.questionsContainer.progress")}
      variant="success"
    />
  );
});

Progress.propTypes = {
  current: number.isRequired,
  total: number.isRequired,
};

Progress.displayName = "Progress";
