import React, { useContext, useState } from "react";
import { Formik, ErrorMessage } from "formik";
import { Alert, Button, Col, Form, Modal } from "react-bootstrap";
import * as yup from "yup";
import { joinClassroom } from "../../../../../services";
import { useSelector } from "react-redux";
import { errorLogger } from "../../../../../errors/errorLogger";
import { bool } from "prop-types";

import styles from "./navbar.module.scss";
import { LanguageContext } from "../../../../../languages";

export const JoinClassroom = ({ isZen }) => {
  const [show, setShow] = useState(false);
  const [error, setError] = useState(null);

  const { displayText } = useContext(LanguageContext);

  const student = useSelector((state) => state.student);

  const handleCloseModal = () => {
    setShow(false);
    setError(null);
  };

  const handleJoinClassroom = (code) => {
    joinClassroom({ studentId: student?._id, classroomId: code })
      .then((res) => {
        if (
          res.data.includes("No hay salones asignados a este código") ||
          res.data.includes("Ya tienes acceso al salón")
        ) {
          setError(res.data);
          return;
        }

        window.location.reload();
      })
      .catch((err) => errorLogger(err));
  };

  const yupschema = yup.object({
    code: yup.string().required("Requerido"),
  });

  return (
    <>
      <div>
        <small
          {...{
            ...(!isZen
              ? { className: styles.navDropdownText }
              : { className: styles.navDropdownTextDisabled }),
            ...(!isZen && { onClick: () => setShow(true) }),
          }}
        >
          {isZen ? (
            <s>{displayText("nav.code.joinLabel")}</s>
          ) : (
            <span>{displayText("nav.code.joinLabel")}</span>
          )}
        </small>
      </div>
      <Modal show={show} onHide={handleCloseModal}>
        <Modal.Body className="bg-light rounded shadow">
          <h4 className="mb-3">{displayText("nav.code.modalTitle")}</h4>
          {error && (
            <Alert className="mb-2" variant="warning">
              <span>{error}</span>
            </Alert>
          )}
          <Formik
            initialValues={{
              code: "",
            }}
            validationSchema={yupschema}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              handleJoinClassroom(values.code);
              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>
                      {displayText("nav.code.modalSubtitle")}
                    </Form.Label>
                    <Form.Control
                      isInvalid={touched.code && !!errors.code}
                      isValid={touched.code && !errors.code}
                      maxLength="24"
                      name="code"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.code}
                    />
                    <ErrorMessage
                      className="text-danger"
                      name="code"
                      component="div"
                    />
                  </Form.Group>
                </Form.Row>
                <Button type="submit" variant="dark">
                  {displayText("nav.code.joinButton")}
                </Button>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

JoinClassroom.propTypes = {
  isZen: bool,
};

JoinClassroom.displayName = "JoinClassroom";
