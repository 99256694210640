import { useCallback } from "react";
import fbApp from "firebase/app";
import { isEqual } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { errorLogger } from "../errors/errorLogger";
import { firebaseAuth } from "../firebase/firebase";
import { fetchStudentByEmail, fetchTeacherInfo } from "../services";
import { loginStudent } from "../redux/actions/student";
import { loginTeacher } from "../redux/actions/teacher";
import { getForwardUrl } from "../utils/helpers";
import { USERS } from "../utils/constants";

export const useFirebase = () => {
  const dispatch = useDispatch();

  const reduxStudent = useSelector((state) => state.student);
  const reduxTeacher = useSelector((state) => state.teacher);

  const loginUserToFirebase = async ({ email, password, persistence }) => {
    try {
      if (isEqual(persistence, "session"))
        await firebaseAuth.setPersistence(fbApp.auth.Auth.Persistence.SESSION);
      if (isEqual(persistence, "local"))
        await firebaseAuth.setPersistence(fbApp.auth.Auth.Persistence.LOCAL);

      await firebaseAuth.signInWithEmailAndPassword(email, password);
    } catch (err) {
      errorLogger(err);
    }
  };

  const logoutUser = useCallback(async (goTo) => {
    try {
      await firebaseAuth.signOut();
      if (goTo) window.location.href = goTo;
    } catch (err) {
      errorLogger(err);
    }
  }, []);

  const sendEmailVerification = useCallback(async (purchase) => {
    const url = getForwardUrl(purchase);
    await firebaseAuth.currentUser.sendEmailVerification({
      url,
      handleCodeInApp: true,
    });
  }, []);

  const forgotPassword = useCallback(async (email) => {
    try {
      await firebaseAuth.sendPasswordResetEmail(email);
    } catch (err) {
      errorLogger(err);
    }
  }, []);

  const handleSetStudentNavigation = useCallback(
    async (user) => {
      if (!user?.emailVerified) {
        // we don't send them back to the root because we want to keep them in the login page
        await logoutUser();
        return USERS.GUEST;
      }

      try {
        const student = await fetchStudentByEmail(user?.email).then(
          ({ data }) => data
        );

        if (!student || student?.isDeleted) {
          await logoutUser("/");
          return USERS.GUEST;
        }

        if (!reduxStudent) {
          dispatch(
            loginStudent({
              _id: student._id,
              email: student.email,
              firstSurname: student.firstSurname,
              isDeleted: student.isDeleted,
              name: student.name,
              secondSurname: student.secondSurname,
            })
          );
        }

        return USERS.STUDENT;
      } catch (err) {
        errorLogger(err);
      }
    },
    [dispatch, logoutUser, reduxStudent]
  );

  const handleSetTeacherNavigation = useCallback(
    async (user) => {
      try {
        const teacherInfo = await fetchTeacherInfo(user.email).then(
          ({ data }) => data
        );

        if (!reduxTeacher) {
          dispatch(loginTeacher(teacherInfo));
        }

        return USERS.TEACHER;
      } catch (err) {
        errorLogger(err);
      }
    },
    [dispatch, reduxTeacher]
  );

  return {
    forgotPassword,
    handleSetStudentNavigation,
    handleSetTeacherNavigation,
    loginUserToFirebase,
    logoutUser,
    sendEmailVerification,
  };
};
