import React, { useContext } from "react";
import Flash from "react-reveal/Flash";
import { LanguageContext } from "../../../languages";

export const Timer = React.memo(({ minutesLeft, secondsLeft }) => {
  const { displayText } = useContext(LanguageContext);

  return (
    <div
      title={displayText("examPage.questionsContainer.timer.title")}
      className="d-inline"
      style={{ color: "#0f5257" }}
    >
      <Flash>
        <i className="fas fa-stopwatch mr-1" />
        <strong>
          {minutesLeft > 1
            ? `${minutesLeft} ${displayText(
                "examPage.questionsContainer.timer.minutes"
              )}`
            : `${secondsLeft} ${displayText(
                "examPage.questionsContainer.timer.seconds"
              )}`}
        </strong>
      </Flash>
    </div>
  );
});

Timer.displayName = "Timer";
