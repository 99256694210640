import React, { memo, useEffect, useMemo, useState } from "react";
import { fetchOneClassroom } from "../../../services";
import { AdminRow, TeacherLayout } from "../../../components";
import { getSchoolNameSortValue } from "../../../utils/helpers";
import { errorLogger } from "../../../errors/errorLogger";
import { deburr, isEmpty, orderBy, uniqBy } from "lodash";
import { PDFLInk } from "../../../student-pages/course/components";

export const TeacherClassroomDetail = memo((props) => {
  const [classroom, setClassroom] = useState();

  const classroomId = props.routeProps.match.params.classroomId;

  useEffect(() => {
    try {
      fetchOneClassroom(classroomId).then((res) => setClassroom(res.data));
    } catch (err) {
      errorLogger(err);
    }
  }, [classroomId]);

  const topics = useMemo(
    () =>
      (classroom?.courses || [])
        .reduce((acc, cv) => {
          const topics = (cv.topics || []).map((topic) => ({
            exams: topic.exams,
            material: topic.material,
            name: `${cv.school}: ${topic.name}`,
            deburredName: deburr(`${cv.school}: ${topic.name}`),
            sortValue: getSchoolNameSortValue(cv.school),
            topicId: topic._id,
          }));
          acc.push(...topics);
          return acc;
        }, [])
        .sort((a, b) => a - b),
    [classroom]
  );

  const sortedAndUniqueTopics = uniqBy(
    orderBy(topics, ["sortValue", "deburredName"]),
    (topic) => topic.topicId
  );

  const sortedAndUniqueMembers = uniqBy(
    orderBy(
      classroom?.members?.map((m) => ({
        ...m,
        displayName: `${m.firstSurname} ${m.secondSurname}, ${m.name}`,
        deburredName: deburr(`${m.firstSurname} ${m.secondSurname} ${m.name}`),
      })),
      ["deburredName"]
    ),
    (member) => member._id
  );

  const renderModalBody = (item) => {
    const sortedExams = orderBy(item?.exams, ["examOrderNumber"]);
    const sortedMaterial = orderBy(item?.material, ["materialOrderNumber"]);
    return (
      <>
        <section className="mb-4">
          <strong>Exámenes</strong>
          {isEmpty(sortedExams) && <div>-</div>}
          {sortedExams?.map((ex, idx) => (
            <div key={idx}>
              <span className="d-block">{`${idx + 1}. ${ex.name}`}</span>
            </div>
          ))}
        </section>
        <section>
          <strong>Material</strong>
          {isEmpty(sortedMaterial) && <div>-</div>}
          {sortedMaterial?.map((mat, idx) => (
            <div key={idx}>
              {mat.type === "video" && (
                <a
                  className="text-info"
                  href={mat.link}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <i className="fas fa-video mr-2" />
                  {mat.name}
                </a>
              )}
              {mat.type === "pdf" && (
                <PDFLInk key={mat._id} path={mat.link} name={mat.name} />
              )}
            </div>
          ))}
        </section>
      </>
    );
  };

  return (
    <TeacherLayout
      backBttn="/teacher/classrooms"
      expanded
      leftBarActive="Salones"
      topNavTitle={classroom?.name}
    >
      <AdminRow rowTitle="Escuela" value={classroom?.institution?.name} />
      <AdminRow
        rowTitle="Desempeño"
        button={{
          label: "Desempeño",
          onClick: () =>
            (window.location.href = `/teacher/classrooms/exams/${classroomId}`),
        }}
      />
      <AdminRow
        rowTitle="Alumnos"
        list={{
          accessor: "displayName",
          data: sortedAndUniqueMembers,
          splitListInColumns: sortedAndUniqueMembers?.length > 20,
          icon: {
            getLink: (item) =>
              `/teacher/classrooms/${classroomId}/student/${item._id}`,
            hoverText: "Ir a alumno",
            svg: "anchor",
          },
        }}
      />
      <AdminRow
        rowTitle="Temas"
        list={{
          data: sortedAndUniqueTopics,
          modal: {
            labelAccessor: "name",
            renderModalBody: (topicSelected) => renderModalBody(topicSelected),
          },
        }}
      />
    </TeacherLayout>
  );
});

TeacherClassroomDetail.displayName = "TeacherClassroomDetail";
