import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getUserFromEmail } from "../../../utils/helpers";
import { Dashboard, DashboardType } from "../dashboard/Dashboard";
import { useEffect } from "react";
import { isEmpty } from "lodash";
import { errorLogger } from "../../../errors/errorLogger";
import { fetchPendingMessages } from "../../../services";

export const TeacherLayout = React.memo(
  ({
    backBttn,
    buttons,
    children,
    expanded,
    hasScrollToTopButton,
    leftBarActive,
    optionsDropdown,
    topNavTitle,
  }) => {
    const [hasPendingMessages, setHasPendingMessages] = useState(false);

    const teacher = useSelector((state) => state.teacher);

    const navItems = [
      { label: "Salones", link: "/teacher/classrooms", icon: "fas fa-users" },
      {
        hasPendingMessages,
        icon: "fas fa-comments",
        label: "Mensajes",
        link: "/teacher/messages",
      },
    ];

    useEffect(() => {
      if (!teacher?._id) return;
      fetchPendingMessages("teacher", teacher._id)
        .then((res) => {
          if (!isEmpty(res.data)) setHasPendingMessages(true);
        })
        .catch((err) => errorLogger(err));
    }, [teacher]);

    return (
      <Dashboard
        {...{
          backBttn,
          buttons,
          children,
          expanded,
          hasScrollToTopButton,
          leftBarActive,
          navItems,
          optionsDropdown,
          topNavTitle,
          type: "[ MAESTRO ]",
          userName: getUserFromEmail(teacher?.email),
        }}
      >
        {children}
      </Dashboard>
    );
  }
);

TeacherLayout.propTypes = DashboardType;

TeacherLayout.displayName = "TeacherLayout";
