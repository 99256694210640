import { firebaseAuth } from "../../../firebase/firebase";
import { registerNewStudent } from "../../../services";
import { isEqual } from "lodash";
import { useFirebase } from "../../../firebase/useFirebase";

export const useSignUpUser = () => {
  const { logoutUser, sendEmailVerification } = useFirebase();

  const signUpUser = async (values) => {
    try {
      // create user in firebase
      const fbRes = await firebaseAuth.createUserWithEmailAndPassword(
        values.email,
        values.password
      );

      // edit user display name to customize the verification email
      await fbRes.user.updateProfile({
        displayName: `${values.name} ${values.firstSurname}`,
      });

      // push new user to database
      await registerNewStudent({
        firebaseUID: fbRes.user.uid,
        name: values.name,
        firstSurname: values.firstSurname,
        secondSurname: values.secondSurname,
        email: values.email,
      }).then((res) => res.data);

      // send email verification
      await sendEmailVerification();

      // logout user
      await logoutUser();
    } catch (err) {
      console.log(err);

      if (isEqual(err?.code, "auth/email-already-in-use"))
        return alert("El correo ya está en uso. ");

      if (isEqual(err?.code, "auth/invalid-email"))
        return alert("El correo es inválido.");

      if (isEqual(err?.code, "auth/weak-password"))
        return alert("La contraseña es inválida.");

      return alert("Ocurrió un error.");
    }
  };

  return { signUpUser };
};
