import React, { forwardRef } from "react";
import { any, string } from "prop-types";
import cn from "classnames";

import styles from "./scrollablediv.module.scss";

export const ScrollableDiv = forwardRef(({ children, className }, ref) => (
  <div className={cn(styles.divStyle, className)} ref={ref}>
    {children}
  </div>
));

ScrollableDiv.propTypes = {
  children: any,
  className: string,
};

ScrollableDiv.displayName = "ScrollableDiv";
