import { orderBy, round } from "lodash";
import React, { useCallback, useMemo } from "react";
import { formatDate } from "../../../../utils/helpers";
import { Container, Table } from "react-bootstrap";
import { IconButton } from "../../../../components";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const StudentModalContent = ({ attempts, setExamDetail, topic }) => {
  const getRowData = useCallback(
    (examId) => {
      let attemptCounter = 0;
      let gradesSum = 0;
      let maxGrade = 0;
      let lastDate = "";
      let attemptAnswers = null;
      for (const attempt of attempts) {
        if (String(attempt.examId) === String(examId)) {
          attemptCounter++;
          gradesSum += attempt.grade;
          if (attempt?.grade > maxGrade) maxGrade = attempt.grade;
          if (attempt?.date > lastDate) {
            lastDate = attempt.date;
            attemptAnswers = attempt.answers;
          }
        }
      }
      return {
        attemptAnswers: attemptCounter > 0 ? attemptAnswers : null,
        attemptCounter,
        average: attemptCounter > 0 ? round(gradesSum / attemptCounter, 2) : "",
        lastDate: attemptCounter > 0 ? formatDate(lastDate, "L") : "",
        maxGrade: attemptCounter > 0 ? maxGrade : "",
      };
    },
    [attempts]
  );

  const sanitizedExamsData = useMemo(() => {
    const data = topic?.topicExams?.map((ex, idx) => {
      const rowData = getRowData(ex?.examId);
      return {
        ...rowData,
        examId: ex.examId,
        examName: ex.examName,
        examOrderNumber: ex.examOrderNumber,
      };
    });
    return orderBy(data, ["examOrderNumber"]);
  }, [getRowData, topic]);

  const data = useMemo(
    () => ({
      datasetIdKey: `lineChart`,
      labels: ["#1", "#2", "#3", "#4", "#5"],
      datasets: [
        {
          borderColor: "#48bf84",
          borderWidth: 3,
          data: sanitizedExamsData?.map(({ maxGrade }) => maxGrade),
          tension: 0.1,
        },
      ],
    }),
    [sanitizedExamsData]
  );

  const fallbackContent = (
    <div className="mt-2 mb-4">Hubo un error en la gráfica</div>
  );

  const options = useMemo(
    () => ({
      clip: false,
      responsive: true,
      interaction: {
        intersect: false,
        mode: "index",
      },
      scales: {
        y: {
          min: 0,
          max: 10,
          ticks: {
            font: {
              size: 16,
            },
          },
        },
        x: {
          ticks: {
            font: {
              size: 16,
            },
          },
        },
      },
      elements: {
        point: {
          backgroundColor: "#48bf84",
          hoverRadius: 12,
          pointStyle: "circle",
          radius: 4,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
      },
    }),
    []
  );

  return (
    <>
      <Table className="shadow-sm" striped bordered hover size="sm">
        <thead>
          <tr>
            <th>#</th>
            <th>Actividad</th>
            <th>Intentos</th>
            <th>Promedio</th>
            <th>Máx. Calificación</th>
            <th>Últ. Fecha</th>
            <th>Últ. Intento</th>
          </tr>
        </thead>
        <tbody>
          {topic?.topicExams?.map((ex, idx) => {
            const {
              attemptAnswers,
              attemptCounter,
              average,
              lastDate,
              maxGrade,
            } = getRowData(ex?.examId);
            return (
              <tr key={ex?.examId}>
                <td>{`${idx + 1}`}</td>
                <td>{`${ex?.examName}`}</td>
                <td>{attemptCounter}</td>
                <td>{average}</td>
                <td>{maxGrade}</td>
                <td>{lastDate}</td>
                <td className="text-center">
                  <IconButton
                    {...{
                      hoverText: !!attemptAnswers
                        ? "Ver respuestas"
                        : "Respuestas no disponibles",
                      isDisabled: !attemptAnswers,
                      onClick: () => setExamDetail(attemptAnswers),
                      svg: "eye",
                    }}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Container className="p-2">
        <Line
          {...{
            data,
            datasetIdKey: `lineChart`,
            fallbackContent,
            options,
          }}
        />
      </Container>
    </>
  );
};
