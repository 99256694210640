import React, { useEffect, useMemo, useState } from "react";
import { AdminLayout } from "../../../components";
import * as yup from "yup";
import {
  fetchInstitutions,
  fetchTeachers,
  newClassroom,
} from "../../../services";
import { useForm } from "../../../hooks/useForm";
import { errorLogger } from "../../../errors/errorLogger";
import { getFullName } from "../../../utils/helpers";

export const AdminNewClassroomPage = () => {
  const [availableTeachers, setAvailableTeachers] = useState();
  const [institutions, setInstitutions] = useState();
  const [languageSelected, setLanguageSelected] = useState(null);

  const { renderForm } = useForm();

  const yupSchema = yup.object({
    name: yup.string().min(3, "Nombre demasiado corto").required("Requerido"),
    language: yup.string().required("Requerido"),
    institution: yup.string(),
    description: yup.string(),
    school: yup.string(),
  });

  useEffect(() => {
    try {
      fetchInstitutions().then(({ data: _institutions }) => {
        const defaultSorting = _institutions.sort((a, b) =>
          String(a?.name).toUpperCase().trim() <
          String(b?.name).toUpperCase().trim()
            ? -1
            : 1
        );
        setInstitutions(defaultSorting);
      });

      fetchTeachers().then(({ data: _teachers }) =>
        setAvailableTeachers(
          _teachers
            .map((t) => ({
              ...t,
              fullName: getFullName(t?.name, t?.firstSurname, t?.secondSurname),
            }))
            .sort((a, b) => (a.fullName < b.fullName ? -1 : 1))
        )
      );
    } catch (err) {
      errorLogger(err);
    }
  }, []);

  const formData = useMemo(
    () => [
      [
        {
          isRequired: true,
          label: "Nombre",
          maxLength: 40,
          name: "name",
          size: 7,
        },
        {
          as: "select",
          hasBlankOption: true,
          label: "Nivel escolar",
          name: "school",
          options: ["Primaria", "Secundaria", "Preparatoria", "Universidad"],
          size: 5,
        },
      ],
      {
        as: "select",
        hasBlankOption: true,
        isRequired: true,
        label: "Idioma",
        name: "language",
        options: ["Español", "Inglés"],
      },
      languageSelected
        ? {
            as: "dropdownWithSearch",
            hasBlankOption: true,
            isCustom: true,
            label: "Escuela",
            name: "institution",
            options: institutions.filter(
              (inst) => inst.language === languageSelected
            ),
            optionsAccessors: { label: "name", value: "_id" },
          }
        : {},
      {
        as: "dropdownWithSearch",
        hasBlankOption: true,
        isCustom: true,
        label: "Maestro",
        name: "teacher",
        options: availableTeachers,
        optionsAccessors: { label: "fullName", value: "_id" },
      },
      {
        as: "textarea",
        label: "Descripción",
        maxLength: 1000,
        name: "description",
        rows: 4,
      },
    ],
    [availableTeachers, institutions, languageSelected]
  );

  const onFormChange = (value) => {
    if (value.target.name === "language" && !!value.target.value) {
      const lang = value.target.value === "Inglés" ? "english" : "spanish";
      setLanguageSelected(lang);
    } else {
      setLanguageSelected(null);
    }
  };

  const handleSubmit = async (values) => {
    const sanitizedValues = {
      ...values,
      language: values.language === "Inglés" ? "english" : "spanish",
    };
    await newClassroom(sanitizedValues);
  };

  return (
    <AdminLayout
      backBttn="/admin/classrooms"
      leftBarActive="Salones"
      topNavTitle="Nuevo Salón"
    >
      {renderForm({
        data: formData,
        doTrimValues: true,
        handleSubmit,
        onFormChange,
        onSubmitSuccess: () => (window.location.href = "/admin/classrooms"),
        yupSchema,
      })}
    </AdminLayout>
  );
};
